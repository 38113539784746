export const REQUEST_HIGHLIGHTS = "highlights/requestHighlights";
export const RECEIVE_HIGHLIGHTS = "highlights/receiveHighlights";

export const START_LISTENING_TO_HIGHLIGHTS_PUSH =
    "highlights/startListeningToHighlightsPush";
export const STOP_LISTENING_TO_HIGHLIGHTS_PUSH =
    "highlights/stopListeningToHighlightsPush";

export const FETCH_HIGHLIGHTS = "highlights/fetchHighlights";
export const FETCH_HIGHLIGHTS_ERROR = "highlights/fetchHighlightsError";

import {find} from "lodash";
import {call, put, takeLatest, takeEvery} from "redux-saga/effects";
import {getMessageFromRequestError} from "@atg-shared/response-mapping/apiMessages";
import * as CmsActions from "./cmsActions";
import * as CmsApi from "./cmsApi";

import {
    REQUEST_ARTICLE,
    REQUEST_ARTICLE_BY_LINK,
    REQUEST_CMS_MISC,
    REQUEST_CMS_PAGE,
    REQUEST_CMS_CAMPAIGN_PAGE,
} from "./cmsConstants";

const getMiscById = (payload, id) => find(payload.items, {id}) || {};
const getFooterLinks = (payload) => getMiscById(payload, "links").items || [];

export function* requestCmsArticle(action) {
    const {id} = action.payload;
    let response;
    try {
        response = yield call(CmsApi.fetchCmsArticle, id);
    } catch (error) {
        const message = getMessageFromRequestError(error);
        yield put(CmsActions.receiveCmsArticleError(id, message));
        return;
    }
    yield put(CmsActions.receiveCmsArticle(id, response.data));
}

export function* requestCmsArticleByLink(action) {
    const {id, link} = action.payload;
    let response;
    try {
        response = yield call(CmsApi.fetchCmsArticleByLink, link);
    } catch (error) {
        const message = getMessageFromRequestError(error);
        yield put(CmsActions.receiveCmsArticleError(id, message));
        return;
    }
    yield put(CmsActions.receiveCmsArticle(id, response.data));
}

function* requestCMSMisc() {
    try {
        const response = yield call(CmsApi.fetchMisc);
        if (!response) return;
        const {data} = response;
        const payload = {
            footerLinks: getFooterLinks(data),
        };
        yield put(CmsActions.receiveCMSMisc(payload));
    } catch (error) {
        yield put(CmsActions.receiveCMSMiscError(error));
    }
}

export function* requestCmsPage(action) {
    const {payload} = action;
    let response;
    try {
        response = yield call(CmsApi.fetchCmsPage, payload.path);
    } catch (error) {
        const message = getMessageFromRequestError(error);
        yield put(CmsActions.receiveCmsPageError(payload.path, message));
        return;
    }
    yield put(CmsActions.receiveCmsPage(payload.path, response.data));
}

export function* requestCmsCampaignPage(action) {
    const {payload} = action;
    let response;
    try {
        response = yield call(CmsApi.fetchCmsPage, payload.path);
    } catch (error) {
        const message = getMessageFromRequestError(error);
        yield put(CmsActions.receiveCmsCampaignPageError(payload.path, message));
        return;
    }
    yield put(CmsActions.receiveCmsCampaignPage(payload.path, response.data));
}

export default function* cmsSaga() {
    yield takeLatest(REQUEST_ARTICLE, requestCmsArticle);
    yield takeLatest(REQUEST_ARTICLE_BY_LINK, requestCmsArticleByLink);
    yield takeLatest(REQUEST_CMS_MISC, requestCMSMisc);
    yield takeEvery(REQUEST_CMS_PAGE, requestCmsPage);
    yield takeEvery(REQUEST_CMS_CAMPAIGN_PAGE, requestCmsCampaignPage);
}

export const REQUEST_CMS_MISC = "REQUEST_CMS_MISC";
export const RECEIVE_CMS_MISC = "RECEIVE_CMS_MISC";
export const REQUEST_CMS_PAGE = "REQUEST_CMS_PAGE";
export const RECEIVE_CMS_PAGE = "RECEIVE_CMS_PAGE";
export const REQUEST_CMS_CAMPAIGN_PAGE = "REQUEST_CMS_CAMPAIGN_PAGE";
export const RECEIVE_CMS_CAMPAIGN_PAGE = "RECEIVE_CMS_CAMPAIGN_PAGE";

export const RESET_ARTICLE = "cms/RESET_ARTICLE";
export const REQUEST_ARTICLE = "cms/REQUEST_ARTICLE";
export const REQUEST_ARTICLE_BY_LINK = "cms/REQUEST_ARTICLE_BY_LINK";
export const RECEIVE_ARTICLE = "cms/RECEIVE_ARTICLE";
export const FAIL_ARTICLE = "cms/FAIL_ARTICLE";

export const RECEIVED_CAMPAIGN_PAGE = "cms/RECEIVED_CAMPAIGN_PAGE";

import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as globalStore} from "./store";

serverLogPlugin(log, "global");
log.setLevel("warn");
configureStoreForAuthentication(globalStore);
configureEmotionCache("global");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const Header = () =>
    import(/* webpackChunkName: "Header" */ "./components/Header");

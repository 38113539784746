import {map, mapValues} from "lodash";
import dayjs from "dayjs";
import {slugifyNoDash} from "@atg/utils/strings";
import type {Highlight, Highlights} from "./highlightsReducer";
import * as ActionTypes from "./highlightsActionTypes";

export type ReceiveHighlightsPushAction = {
    type: typeof ActionTypes.RECEIVE_HIGHLIGHTS;
    payload: Highlights;
};
export type StartListeningToHighlightsPushAction = {
    type: typeof ActionTypes.START_LISTENING_TO_HIGHLIGHTS_PUSH;
};
export type StopListeningToHighlightsPushAction = {
    type: typeof ActionTypes.STOP_LISTENING_TO_HIGHLIGHTS_PUSH;
};
export type FetchHighlightsAction = {
    type: typeof ActionTypes.FETCH_HIGHLIGHTS;
};
export type ReceiveHighlightsAction = {
    type: typeof ActionTypes.RECEIVE_HIGHLIGHTS;
    payload: Highlights;
};
export type FetchHighlightsErrorAction = {
    type: typeof ActionTypes.FETCH_HIGHLIGHTS_ERROR;
};

export type Action =
    | ReceiveHighlightsPushAction
    | StartListeningToHighlightsPushAction
    | StopListeningToHighlightsPushAction
    | FetchHighlightsAction
    | ReceiveHighlightsAction
    | FetchHighlightsErrorAction;

const createGameUrl = (highlights: Highlight) => {
    if (highlights.status === "scheduled") return undefined;
    const date = dayjs(highlights.startTime).format("YYYY-MM-DD");
    const gameType = highlights.type;
    const tracks = map(highlights.trackNames, slugifyNoDash).join("-");
    return ["/spel", date, gameType, tracks].join("/");
};

export const parseHighlights = (highlights: Highlights): Highlights =>
    mapValues(highlights, (highlight) => ({
        ...highlight,
        url: createGameUrl(highlight as Highlight),
    })) as Highlights;

export const fetchHighlights = (): FetchHighlightsAction => ({
    type: ActionTypes.FETCH_HIGHLIGHTS,
});
export const fetchHighlightsError = (): FetchHighlightsErrorAction => ({
    type: ActionTypes.FETCH_HIGHLIGHTS_ERROR,
});
export const receiveHighlights = (payload: Highlights): ReceiveHighlightsAction => ({
    type: ActionTypes.RECEIVE_HIGHLIGHTS,
    payload: parseHighlights(payload),
});
export const receiveHighlightsPush = (data: Highlights): ReceiveHighlightsPushAction => ({
    type: ActionTypes.RECEIVE_HIGHLIGHTS,
    payload: parseHighlights(data),
});
export const startListeningToHighlightsPush =
    (): StartListeningToHighlightsPushAction => ({
        type: ActionTypes.START_LISTENING_TO_HIGHLIGHTS_PUSH,
    });
export const stopListeningToHighlightsPush = (): StopListeningToHighlightsPushAction => ({
    type: ActionTypes.STOP_LISTENING_TO_HIGHLIGHTS_PUSH,
});

import {type Reducer, combineReducers} from "redux";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import {LOADING} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import {OK} from "@atg-horse-shared/legacy/domain/loadingstatus";
import {
    SET_ACTIVE_HOT_GAMES_TAB,
    REQUEST_HOT_GAMES,
    RECEIVE_HOT_GAMES,
} from "./hotGamesActionTypes";

export type LoadingStatus = {
    code: string;
    isLoading: boolean;
};

export type State = {
    currentHotGame: number;
    data: Array<Game>;
    loadingStatus: LoadingStatus;
};

export type GlobalState = {
    horse: {
        hotGames: State;
    };
};

/**
 * @todo use `createFetchReducer` from `atg-fetch-redux` to handle loading states instead
 */
const hotGamesLoadingStatus = (state = {}, action: any) => {
    const {type, payload, error} = action;
    switch (type) {
        case REQUEST_HOT_GAMES:
            return LOADING;
        case RECEIVE_HOT_GAMES:
            if (error) {
                return {
                    code: "failed",
                    isLoading: false,
                    error: {
                        httpCode: payload.error.httpCode,
                        type: payload.error.type,
                    },
                };
            }

            return OK;
        default:
            return state;
    }
};

const initialState = 0;

const setActiveTab: Reducer<any, any> = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_ACTIVE_HOT_GAMES_TAB:
            return payload.activeTab;
        default:
            return state;
    }
};

const hotGamesData = (state = [], action: any) => {
    const {type, payload} = action;
    switch (type) {
        case RECEIVE_HOT_GAMES:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({
    currentHotGame: setActiveTab,
    data: hotGamesData,
    loadingStatus: hotGamesLoadingStatus,
});

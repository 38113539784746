export const UNEXPECTED_ERROR_MESSAGE = "Ett oförutsett tekniskt fel har inträffat";

export const DEFAULT_MESSAGE_MAPPING = {
    default: UNEXPECTED_ERROR_MESSAGE,
    "0": "Anslutningen till servern bröts. Kontrollera nätverket.",
    "401": "Felaktigt användarnamn/lösenord",
    "404": "Resursen du söker efter kunde ej hittas.",
    "500": UNEXPECTED_ERROR_MESSAGE,
    "503": "Tjänsten är inte tillgänglig just nu, försök igen senare.",
};

export const getMessageFromCode = (code, mapping) =>
    mapping[String(code)] || mapping.default;

export const getMessageFromRequestError = (error, mapping = DEFAULT_MESSAGE_MAPPING) =>
    getMessageFromCode(error.response?.meta?.code, mapping); // Splunk logs show that response.meta can be undefined

/**
 * @deprecated use `getMessageFromRequestError` directly instead, or switch to the `atg-fetch-redux`
 * helper library
 */
export const deprecated_defaultErrorTransform = (
    response,
    messages = DEFAULT_MESSAGE_MAPPING,
) => {
    const message =
        messages[String(response.meta.code)] ||
        DEFAULT_MESSAGE_MAPPING[String(response.meta.code)] ||
        DEFAULT_MESSAGE_MAPPING["500"] ||
        response;
    const status = {
        code: "failed",
        isLoading: false,
        error: {
            httpCode: response.meta.code,
            type: response.data.status,
            message,
        },
    };
    return status;
};

export const GAMBLING_RESULT_FETCH = "user-gambling-summary/GAMBLING_RESULT_FETCH";
export const GAMBLING_RESULT_SUCCESS = "user-gambling-summary/GAMBLING_RESULT_SUCCESS";

export const LATEST_SUCCESFUL_LOGIN_TIME_FETCH =
    "user-gambling-summary/LATEST_SUCCESFUL_LOGIN_TIME_FETCH";

export const LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS =
    "user-gambling-summary/LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS";

export const NAR_BLOCK_FETCH = "user-gambling-summary/NAR_BLOCK_FETCH";

export const NAR_BLOCK_SUCCESS = "user-gambling-summary/NAR_BLOCK_SUCCESS";

export const NAR_CHECK_TRIGGER = "user-gambling-summary/NAR_CHECK_TRIGGER";

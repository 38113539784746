import {createFetchReducer} from "@atg-shared/fetch-redux";
import {type FetchReducer, type FetchState} from "@atg-shared/fetch-types";
import {
    REQUEST_HARRY_BAGS,
    RECEIVE_HARRY_BAGS,
    REQUEST_HARRY_SUBSCRIPTIONS,
    RECEIVE_HARRY_SUBSCRIPTIONS,
    REQUEST_SIMPLE_HARRY,
    RECEIVE_SIMPLE_HARRY,
    RECEIVE_HARRY_TOMORROW,
    REQUEST_HARRY_TOMORROW,
    RECEIVE_HARRY_CAMPAIGN,
    REQUEST_HARRY_CAMPAIGN,
} from "./harryActions";

const INITIAL_STATE = {};

// @ts-expect-error
export const simpleHarry: FetchReducer<
    {
        [key: string]: any;
    },
    {
        [key: string]: any;
    }
> = createFetchReducer(
    REQUEST_SIMPLE_HARRY,
    RECEIVE_SIMPLE_HARRY,
    "",
    (
        state: FetchState<{
            [key: string]: any;
        }>,
        action: {
            [key: string]: any;
        },
    ) => {
        switch (action.type) {
            case RECEIVE_SIMPLE_HARRY:
                if (action.error) return state;
                return action.payload;
            default:
                return state;
        }
    },
    INITIAL_STATE,
);

export function harryTomorrow(
    state: {
        [key: string]: any;
    } = INITIAL_STATE,
    action: {
        [key: string]: any;
    },
) {
    const {type, payload, error} = action;

    switch (type) {
        case RECEIVE_HARRY_TOMORROW:
            if (error) return INITIAL_STATE;
            return payload;
        case REQUEST_HARRY_TOMORROW:
        default:
            return state;
    }
}

export function harryCampaign(
    state: {
        [key: string]: any;
    } = INITIAL_STATE,
    action: {
        [key: string]: any;
    },
) {
    const {type, payload, error} = action;

    switch (type) {
        case RECEIVE_HARRY_CAMPAIGN:
            if (error) return INITIAL_STATE;
            return payload;
        case REQUEST_HARRY_CAMPAIGN:
        default:
            return state;
    }
}

// @ts-expect-error
export const harryBags: FetchReducer<
    {
        [key: string]: any;
    },
    {
        [key: string]: any;
    }
> = createFetchReducer(
    REQUEST_HARRY_BAGS,
    RECEIVE_HARRY_BAGS,
    "",
    (
        state: FetchState<{
            [key: string]: any;
        }>,
        action: {
            [key: string]: any;
        },
    ) => {
        switch (action.type) {
            case RECEIVE_HARRY_BAGS:
                if (action.error) return state;
                return action.payload;
            default:
                return state;
        }
    },
    INITIAL_STATE,
);

// @ts-expect-error
export const harrySubscriptions: FetchReducer<
    {
        [key: string]: any;
    },
    {
        [key: string]: any;
    }
> = createFetchReducer(
    REQUEST_HARRY_SUBSCRIPTIONS,
    RECEIVE_HARRY_SUBSCRIPTIONS,
    "",
    (
        state: FetchState<{
            [key: string]: any;
        }>,
        action: {
            [key: string]: any;
        },
    ) => {
        switch (action.type) {
            case RECEIVE_HARRY_SUBSCRIPTIONS:
                if (action.error) return state;
                return action.payload;
            default:
                return state;
        }
    },
    INITIAL_STATE,
);

// https://jira-atg.riada.cloud/secure/RapidBoard.jspa?rapidView=1561&projectKey=HRS1&view=detail&selectedIssue=HRS1-7228
// Make better
export default {
    harryBags,
    harryCampaign,
    harryTomorrow,
    simpleHarry,
};

import * as Storage from "@atg-shared/storage";

const PRO_START_PAGE_STORAGE_KEY = "doNotShowProPage";

export const ACTIVATE_PRO_START_PAGE = "ACTIVATE_PRO_START_PAGE";
export const DEACTIVATE_PRO_START_PAGE = "DEACTIVATE_PRO_START_PAGE";

type ActivateProStartPageAction = {
    type: typeof ACTIVATE_PRO_START_PAGE;
};

type DeactivateProStartPageAction = {
    type: typeof DEACTIVATE_PRO_START_PAGE;
};

export type UserSettingsAction =
    | ActivateProStartPageAction
    | DeactivateProStartPageAction;

export const activateProStartPage = (): ActivateProStartPageAction => {
    Storage.removeItem(PRO_START_PAGE_STORAGE_KEY);
    return {
        type: ACTIVATE_PRO_START_PAGE,
    };
};

export const deactivateProStartPage = (): DeactivateProStartPageAction => {
    Storage.setItem(PRO_START_PAGE_STORAGE_KEY, "true");
    return {
        type: DEACTIVATE_PRO_START_PAGE,
    };
};

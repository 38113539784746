import type {Reducer} from "redux";
import {combineReducers} from "redux";
import * as Storage from "@atg-shared/storage";
import {ACTIVATE_PRO_START_PAGE, DEACTIVATE_PRO_START_PAGE} from "./userSettingsActions";
import type {Action} from "./index";

const PRO_START_PAGE_STORAGE_KEY = "doNotShowProPage";

type ProPageActiveState = boolean;
type UserSettingsState = {
    proStartPageActive: ProPageActiveState;
};

export type GlobalUserSettingsState = {
    userSettings: UserSettingsState;
};

function getInitialProStartPageState() {
    const doShowStartPage = !Storage.getItem(PRO_START_PAGE_STORAGE_KEY);
    return doShowStartPage;
}

function proStartPageActive(
    state: ProPageActiveState = getInitialProStartPageState(),
    action: Action,
): ProPageActiveState {
    const {type} = action;
    switch (type) {
        case ACTIVATE_PRO_START_PAGE:
            return true;
        case DEACTIVATE_PRO_START_PAGE:
            return false;
        default:
            return state;
    }
}

const userSettings: Reducer<UserSettingsState, Action> = combineReducers({
    proStartPageActive,
});

export default userSettings;

import {combineReducers, type Reducer} from "redux";
import {isApp} from "@atg-shared/system";
import {AuthActions} from "@atg-shared/auth";
import {
    type Action,
    START_LOGIN_FLOW,
    START_REGISTER_FLOW,
    FINISH_MEMBER_FLOW,
    CANCELLED_LOGIN_FLOW,
} from "./memberActions";
import {type FlowType, MEMBER_MODAL_FLOW} from "./memberFlowType";

export type MemberFlowState = {
    isLogin: boolean;
    isRegister: boolean;
    flowType: FlowType;
    flowTriggerAction: AuthActions.FlowTriggerAction | null;
    isReturningMember: boolean;
    isForcedAuth: boolean;
    loginMessage: string;
};

export type GlobalMemberFlowState = {
    member: MemberFlowState;
};

const isLogin = (state = false, action: Action): boolean => {
    switch (action.type) {
        case START_LOGIN_FLOW:
            return true;
        case START_REGISTER_FLOW:
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return false;
        default:
            return state;
    }
};

const isRegister = (state = false, action: Action): boolean => {
    switch (action.type) {
        case START_REGISTER_FLOW:
            return true;
        case START_LOGIN_FLOW:
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return false;
        default:
            return state;
    }
};

const flowTriggerAction = (
    state: AuthActions.FlowTriggerAction | null = null,
    action: Action,
) => {
    switch (action.type) {
        case AuthActions.START_AUTHENTICATION_FLOW: {
            const {
                payload: {options},
            } = action;
            return options && options.triggeredBy ? options.triggeredBy : state;
        }
        case START_LOGIN_FLOW:
        case START_REGISTER_FLOW: {
            const {payload} = action;
            return payload && payload.triggeredBy ? payload.triggeredBy : state;
        }
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return null;
        default:
            return state;
    }
};

const flowType = (state: FlowType = MEMBER_MODAL_FLOW, action: Action) => {
    switch (action.type) {
        case AuthActions.START_AUTHENTICATION_FLOW: {
            const {
                payload: {options},
            } = action;
            return options && options.flowType ? options.flowType : MEMBER_MODAL_FLOW;
        }
        case START_LOGIN_FLOW:
        case START_REGISTER_FLOW: {
            const {payload} = action;
            return payload && payload.flowType ? payload.flowType : MEMBER_MODAL_FLOW;
        }
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return MEMBER_MODAL_FLOW;
        default:
            return state;
    }
};

// In the app, users are always returning members since we do
//  not offer registration via the app at the moment.
const isReturningMember = (state = isApp, action: Action): boolean => {
    switch (action.type) {
        case FINISH_MEMBER_FLOW:
            return true;
        default:
            return state;
    }
};

const isForcedAuth = (state = false, action: Action): boolean => {
    switch (action.type) {
        case AuthActions.START_AUTHENTICATION_FLOW: {
            const {payload} = action;
            return payload.isForced;
        }
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return false;
        default:
            return state;
    }
};

/**
 * Information message to be shown as part of the login modal, for example to give hints to the user
 * regarding why they were logged out.
 */
const loginMessage = (state = "", action: Action): string => {
    switch (action.type) {
        case AuthActions.START_AUTHENTICATION_FLOW: {
            const {
                payload: {options},
            } = action;
            return options.loginMessage ?? "";
        }
        case START_REGISTER_FLOW:
        case FINISH_MEMBER_FLOW:
        case CANCELLED_LOGIN_FLOW:
            return "";
        default:
            return state;
    }
};

const member = combineReducers({
    isLogin,
    isRegister,
    flowTriggerAction,
    flowType,
    isReturningMember,
    isForcedAuth,
    loginMessage,
});

export default member;

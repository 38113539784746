import {call, put, select, takeLatest, takeLeading, takeEvery} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import {DepositMethods, DepositMessages} from "@atg-payment-shared/deposit-utils";
import {type AtgResponse} from "@atg-shared/fetch-types";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../actions/actions";
import * as DepositSelectors from "../selectors/selectors";
import * as DepositApi from "../api/api";
import type {DepositOptionsResponse} from "../domainTypes";
import {type DepositResponseError} from "./helpers/sagaHelpers";
import {
    abortTrustlyDeposit,
    checkDepositStatusTrustly,
    handleRedirectFlow,
} from "./trustly/trustlySagas";
import {cancelSwishDirect, handleSwishDirectFlow} from "./swish/swishSagas";
import {finalizeDepositIframe, handleIFrameFlow} from "./iframe/iframeSagas";
import {cancelCardDepositInIframe, deleteBankCardFlow} from "./card/cardSagas";

export function* depositFlow({payload}: Record<string, any>): SagaIterator {
    const isLoading = yield select(DepositSelectors.isLoading);
    if (isLoading) return;

    const option = payload?.option;

    yield put(DepositActions.depositStart());

    switch (option.id) {
        case DepositMethods.trustly:
            yield call(handleRedirectFlow, payload);
            break;
        case DepositMethods.existingCard:
        case DepositMethods.newCard: {
            // API change, don't send phoneNumber when using card
            delete payload.phoneNumber;
            const noCvc = yield select(DepositSelectors.depositWithoutCvc);
            const ssnValidation = yield select(DepositSelectors.depositWithSsnValidation);
            yield call(handleIFrameFlow, {...payload, noCvc, ssnValidation});
            break;
        }
        case DepositMethods.swish:
            yield call(handleSwishDirectFlow, {...payload});
            break;
        default:
            log.warn("Deposit saga called with unknown option", {option});
            break;
    }
}

export function* depositOptionsFlow(): SagaIterator {
    yield put(LimitsActions.fetchDepositLimits());
    let response: AtgResponse<DepositOptionsResponse>;
    try {
        response = yield call(DepositApi.fetchOptionsIframe);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        if (!err.response) {
            throw error;
        }

        log.error(
            `Fetching payment options failed with status ${err.response.meta.statusCode}`,
            {
                error: serializeError(error),
            },
        );

        if (err.response.data.status === "DENIED") {
            yield put(DepositActions.depositBlocked());
        }
        yield put(
            DepositActions.fetchOptionsFailure(
                DepositMessages().DEPOSIT_OPTIONS_FETCH_ERROR,
            ),
        );

        return;
    }
    yield put(DepositActions.fetchOptionsSuccess(response.data.deposit));
}

export default function* depositSaga(): SagaIterator {
    yield takeEvery(DepositActionConstants.DEPOSIT_MONEY, depositFlow);
    yield takeLatest(DepositActionConstants.DEPOSIT_FINALIZE, checkDepositStatusTrustly);
    // Using takeLeading here makes sure that finalizeDepositIframe saga is triggered only once (on first dispatch)
    // even if the DEPOSIT_FINALIZE_IFRAME action is dispatched multiple times from the component before the call
    // to the BE has finished (this is to avoid a race condition in BE when finalizeDepositIframe is triggered multiple times in parallel)
    yield takeLeading(
        DepositActionConstants.DEPOSIT_FINALIZE_IFRAME,
        finalizeDepositIframe,
    );

    yield takeLatest(DepositActionConstants.DELETE_BANK_CARD, deleteBankCardFlow);
    yield takeLatest(
        [
            DepositActionConstants.FETCH_OPTIONS,
            DepositActionConstants.DEPOSIT_FLOW_STARTED,
            DepositActionConstants.DEPOSIT_FAILURE,
        ],
        depositOptionsFlow,
    );
    yield takeEvery(
        DepositActionConstants.CANCEL_CARD_DEPOSIT_IN_IFRAME,
        cancelCardDepositInIframe,
    );
    yield takeEvery(DepositActionConstants.ABORT_TRUSTLY_DEPOSIT, abortTrustlyDeposit);
    yield takeEvery(DepositActionConstants.CANCEL_SWISH_DIRECT, cancelSwishDirect);
}

import {call, take, takeEvery} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {subscribe} from "@atg-frame-shared/push";
import {receiveHotGamesPush} from "./hotGamesActions";
import {
    START_LISTENING_TO_HOT_GAMES_PUSH,
    STOP_LISTENING_TO_HOT_GAMES_PUSH,
} from "./hotGamesActionTypes";

export const TOPIC = "racinginfo/hotgames";

export const getMessageCallback =
    (dispatch: (...args: Array<any>) => any): ((...args: Array<any>) => any) =>
    (data: any): any =>
        dispatch(receiveHotGamesPush(data));

export function* listenToHotGames(
    dispatch: (...args: Array<any>) => any,
    subscribeFn: (...args: Array<any>) => any,
): SagaIterator<void> {
    const messageCallback = yield call(getMessageCallback, dispatch);
    const unsubscribe = yield call(subscribeFn, TOPIC, messageCallback);

    yield take(STOP_LISTENING_TO_HOT_GAMES_PUSH);
    yield call(unsubscribe);
}

// Use dispatch in order to be able to dispatch in callback function when push is received.
export default function* hotGamesPushListenerSaga(
    dispatch: (...args: Array<any>) => any,
): SagaIterator<void> {
    yield takeEvery(
        START_LISTENING_TO_HOT_GAMES_PUSH,
        listenToHotGames,
        dispatch,
        subscribe,
    );
}

import * as Sfx from "redux-saga/effects";
import {ApolloClientInstance} from "@atg-global-shared/verticals";
import {LOGIN_FINISHED, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";

async function clearApolloCache() {
    await ApolloClientInstance.clearCache("horse");
}

/**
 * When a user logs out we should clear the Apollo cache, since it can contain user sensitive data (see [PEGASUS-706] for example).
 */
export default function* cleanupApolloCacheOnLogoutSaga() {
    yield Sfx.takeEvery(LOGOUT_FINISHED, clearApolloCache);
    yield Sfx.takeEvery(LOGIN_FINISHED, clearApolloCache);
}

import {call, take, takeEvery} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {Dispatch} from "redux";
import {subscribe} from "@atg-frame-shared/push";
import {receiveHighlightsPush} from "./highlightsActions";
import {
    STOP_LISTENING_TO_HIGHLIGHTS_PUSH,
    START_LISTENING_TO_HIGHLIGHTS_PUSH,
} from "./highlightsActionTypes";

export const TOPIC = "racinginfo/highlights";
export const getMessageCallback =
    (dispatch: Dispatch<any>): ((...args: Array<any>) => any) =>
    (data: any): any =>
        dispatch(receiveHighlightsPush(data));
export function* listenToHighlights(
    dispatch: Dispatch<any>,
    subscribeFn: (...args: Array<any>) => any,
): SagaIterator<void> {
    const messageCallback = yield call(getMessageCallback, dispatch);
    const unsubscribe = yield call(subscribeFn, TOPIC, messageCallback);
    yield take(STOP_LISTENING_TO_HIGHLIGHTS_PUSH);
    yield call(unsubscribe);
} // Use dispatch in order to be able to dispatch in callback function when push is received.

export default function* highlightsPushListenerSaga(dispatch: Dispatch<any>) {
    yield takeEvery(
        START_LISTENING_TO_HIGHLIGHTS_PUSH,
        listenToHighlights,
        dispatch,
        subscribe,
    );
}

import _ from "lodash";
import {combineReducers} from "redux";
import {
    REQUEST_ARTICLE,
    REQUEST_ARTICLE_BY_LINK,
    RECEIVE_ARTICLE,
    REQUEST_CMS_MISC,
    RECEIVE_CMS_MISC,
    RECEIVE_CMS_PAGE,
    REQUEST_CMS_PAGE,
    REQUEST_CMS_CAMPAIGN_PAGE,
    RECEIVE_CMS_CAMPAIGN_PAGE,
    RESET_ARTICLE,
} from "./cmsConstants";
import {convertToCampaignPage} from "./cms";

export const INITIAL_CMS_PAGE_STATE = {
    isLoading: false,
    error: false,
};

/*
 *  Page reducer
 */
export const pages = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_CMS_PAGE:
        case REQUEST_CMS_CAMPAIGN_PAGE:
            return {
                ...state,
                [action.payload.path]: {
                    isLoading: true,
                    error: false,
                },
            };
        case RECEIVE_CMS_PAGE:
            if (action.error) {
                return {
                    ...state,
                    [action.payload.path]: {
                        isLoading: false,
                        error: true,
                        message: action.payload.message,
                    },
                };
            }
            return {
                ...state,
                [action.payload.path]: {
                    page: action.payload.page,
                    isLoading: false,
                    error: false,
                },
            };
        case RECEIVE_CMS_CAMPAIGN_PAGE:
            if (action.error) {
                return {
                    ...state,
                    [action.payload.path]: {
                        isLoading: false,
                        error: true,
                        message: action.payload.message,
                    },
                };
            }
            return {
                ...state,
                [action.payload.path]: {
                    page: convertToCampaignPage(action.payload.page),
                    isLoading: false,
                    error: false,
                },
            };
        default:
            return state;
    }
};

export const INITIAL_CMS_ARTICLE_STATE = {
    isLoading: false,
    error: false,
};

export const article = (state = INITIAL_CMS_ARTICLE_STATE, action) => {
    switch (action.type) {
        case REQUEST_ARTICLE:
        case REQUEST_ARTICLE_BY_LINK:
            return {
                isLoading: true,
                error: false,
                code: "loading",
            };
        case RECEIVE_ARTICLE: {
            if (action.error) {
                return {
                    isLoading: false,
                    error: true,
                    message: action.payload.message,
                };
            }
            return {
                article: action.payload.article,
                isLoading: false,
                error: false,
            };
        }
        case RESET_ARTICLE: {
            return INITIAL_CMS_ARTICLE_STATE;
        }
        default:
            return state;
    }
};

export function misc(state = {}, action) {
    switch (action.type) {
        case REQUEST_CMS_MISC:
            return state;
        case RECEIVE_CMS_MISC: {
            const {payload} = action;
            if (payload.error) return state;
            return payload;
        }
        default:
            return state;
    }
}

const cmsReducer = combineReducers({
    misc,
    pages,
    article,
});

export default cmsReducer;

import type {Storage} from ".";

/**
 * Adapts a "regular" synchronous storage to asynchronous using promises
 */
class AsyncStorageAdapter {
    storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    setItem(key: string, value: string) {
        try {
            this.storage.setItem(key, value);
            return Promise.resolve();
        } catch (e: unknown) {
            return Promise.reject(e);
        }
    }

    getItem(key: string) {
        try {
            const value = this.storage.getItem(key);
            return Promise.resolve(value);
        } catch (e: unknown) {
            return Promise.reject(e);
        }
    }

    removeItem(key: string) {
        try {
            this.storage.removeItem(key);
            return Promise.resolve();
        } catch (e: unknown) {
            return Promise.reject(e);
        }
    }
}

export default AsyncStorageAdapter;

import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import {call, take, takeLatest, fork, cancel, select} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {get} from "lodash";
import * as Storage from "@atg-shared/storage";
import {deprecated_logEvent} from "@atg-shared/analytics";
import {DepositMethods} from "@atg-payment-shared/deposit-utils";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {type AmountType, DepositActionConstants} from "@atg-payment-shared/deposit-types";
import * as ModalActions from "atg-modals/modalActionTypes";
import type {DepositMoneyPayload} from "../actions/actionTypes";
import * as DepositSelectors from "../selectors/selectors";
import {getStoredUserDepositPreference} from "../saga/helpers/sagaHelpers";
import * as DepositAnalytics from "./analytics";

dayjs.extend(dayjsDuration);

export const DEPOSIT_AMOUNT_TYPE = "depositAmountType";
export const DEPOSIT_PRE_SELECTED_BTN_NR = "depositPreSelectedBtnNr";

type DepositFlowData = {
    payload: DepositMoneyPayload;
    duration: number;
    timesChangedAmount: number;
    selectedPayment: string;
};

export function* evaluateSelectedPayment(payload?: DepositMoneyPayload): SagaIterator {
    const userName = yield select(UserSelectors.getUsername);
    const storedSelectedOption = yield call(getStoredUserDepositPreference, userName);

    if (!storedSelectedOption) return "unknown";

    if (!payload) {
        const selectedOption = yield select(DepositSelectors.selectedOption);

        return storedSelectedOption.id === selectedOption?.id
            ? "pre-selected"
            : "changed";
    }

    const optionId = get(payload, "option.id");

    return storedSelectedOption.id === optionId ? "pre-selected" : "changed";
}

export function* persistAmountType(
    optionId: string,
    amountType: AmountType,
    preSelectedBtnNr: number | null,
): SagaIterator {
    if (optionId === DepositMethods.existingCard || optionId === DepositMethods.swish)
        return;

    yield call(Storage.setItem, DEPOSIT_AMOUNT_TYPE, amountType);
    if (preSelectedBtnNr !== null) {
        yield call(
            Storage.setItem,
            DEPOSIT_PRE_SELECTED_BTN_NR,
            preSelectedBtnNr.toString(),
        );
    }
}

export function* abortDeposit(): SagaIterator {
    const action = yield take(ModalActions.MODAL_CLOSE);
    if (action.payload === "depositModal") {
        // handles closing the deposit modal in regular deposit flow
        const depositAbortEvent = yield call(DepositAnalytics.depositAbort, "deposit");
        yield call(deprecated_logEvent, depositAbortEvent);
    }
}

export function* depositResultFlow(
    optionId: string,
    amountType: AmountType,
    preSelectedBtnNr: number | null,
): SagaIterator {
    const depositResult = yield take([
        DepositActionConstants.DEPOSIT_FAILURE,
        DepositActionConstants.DEPOSIT_SUCCESS,
    ]);

    if (depositResult.type === DepositActionConstants.DEPOSIT_FAILURE) {
        // failed deposit
        const {
            message: {id: messageId},
        } = depositResult.payload;
        const depositFailedEvent = yield call(
            DepositAnalytics.depositFail,
            optionId,
            messageId,
        );
        yield call(deprecated_logEvent, depositFailedEvent);
    } else {
        const {amount, delayed} = depositResult.payload;
        const selectedPayment = yield call(evaluateSelectedPayment);

        const depositSuccessEvent = yield call(
            DepositAnalytics.depositSuccess,
            amount,
            delayed,
            optionId,
            amountType,
            preSelectedBtnNr,
            selectedPayment,
        );
        yield call(deprecated_logEvent, depositSuccessEvent);
    }
}

export function* depositFlow({
    payload,
    duration,
    timesChangedAmount,
    selectedPayment,
}: DepositFlowData): SagaIterator {
    const {
        option: {id: optionId},
        amount,
        amountType,
        preSelectedBtnNr,
    } = payload;

    const depositStartEvent = yield call(
        DepositAnalytics.depositStart,
        amount,
        optionId,
        duration,
        timesChangedAmount,
        selectedPayment,
    );
    yield call(deprecated_logEvent, depositStartEvent);
    yield call(persistAmountType, optionId, amountType, preSelectedBtnNr);
    yield call(depositResultFlow, optionId, amountType, preSelectedBtnNr);
}

export function* startDeposit(): SagaIterator {
    const abortDepositWatcher = yield fork(abortDeposit);
    // Run in loop to handle cases when user "starts over" with a deposit without closing and re-opening the modal
    while (true) {
        const timeStarted = dayjs();
        let timesChangedAmount = 0;
        let action = yield take([
            DepositActionConstants.DEPOSIT_MONEY,
            DepositActionConstants.SET_DEPOSIT_AMOUNT,
        ]);
        while (action.type === DepositActionConstants.SET_DEPOSIT_AMOUNT) {
            timesChangedAmount += 1;
            action = yield take([
                DepositActionConstants.DEPOSIT_MONEY,
                DepositActionConstants.SET_DEPOSIT_AMOUNT,
            ]);
        }
        const {payload} = action;
        const timeEnded = dayjs();
        const duration = dayjs.duration(timeEnded.diff(timeStarted)).seconds();
        const selectedPayment = yield call(evaluateSelectedPayment, payload);

        yield fork(depositFlow, {payload, duration, timesChangedAmount, selectedPayment});

        const result = yield take([
            DepositActionConstants.DEPOSIT_SUCCESS,
            DepositActionConstants.DEPOSIT_FAILURE,
        ]);
        // Cancel the abort deposit watcher after successful deposit
        if (result.type === DepositActionConstants.DEPOSIT_SUCCESS) {
            yield cancel(abortDepositWatcher);
            return;
        }
    }
}

export function* finalizeDepositFlow(): SagaIterator {
    const selectedOption = yield select(DepositSelectors.selectedOption);
    const optionId = selectedOption?.id ?? "unknown";

    const amountType: AmountType = yield call(Storage.getItem, DEPOSIT_AMOUNT_TYPE);
    const preSelectedBtnNr: number | null = yield call(
        Storage.getItem,
        DEPOSIT_PRE_SELECTED_BTN_NR,
    );
    yield call(Storage.removeItem, DEPOSIT_AMOUNT_TYPE);
    yield call(Storage.removeItem, DEPOSIT_PRE_SELECTED_BTN_NR);

    yield call(depositResultFlow, optionId, amountType, preSelectedBtnNr);
}

export default function* depositAnalyticsSaga(): SagaIterator {
    yield takeLatest(
        [
            DepositActionConstants.DEPOSIT_FLOW_STARTED,
            DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL,
        ],
        startDeposit,
    );
    yield takeLatest(DepositActionConstants.DEPOSIT_FINALIZE, finalizeDepositFlow);
}

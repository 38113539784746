import type {Brackets} from "./harryBoyFeeReducer";

export const FETCH_HARRY_BOY_FEE_BRACKETS = "coupon/fetchHarryBoyFeeBrackets" as const;
export const RECEIVE_HARRY_BOY_FEE_BRACKETS =
    "coupon/receiveHarryBoyFeeBrackets" as const;

type FetchHarryBoyFeeBracketsAction = {
    type: typeof FETCH_HARRY_BOY_FEE_BRACKETS;
};

type ReceiveHarryBoyFeeBracketsAction = {
    type: typeof RECEIVE_HARRY_BOY_FEE_BRACKETS;
    payload: Brackets;
    error?: boolean;
};

export type HarryBoyFeeBracketsAction =
    | FetchHarryBoyFeeBracketsAction
    | ReceiveHarryBoyFeeBracketsAction;

export const fetchHarryBoyFeeBrackets = (): FetchHarryBoyFeeBracketsAction => ({
    type: FETCH_HARRY_BOY_FEE_BRACKETS,
});

export const receiveHarryBoyFeeBrackets = (
    payload: Brackets,
    error?: boolean,
): ReceiveHarryBoyFeeBracketsAction => ({
    type: RECEIVE_HARRY_BOY_FEE_BRACKETS,
    payload,
    error,
});

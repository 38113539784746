export enum FileBetActionType {
    REQUEST_FILE_BETS = "REQUEST_FILE_BETS",
    RECEIVED_FILE_BETS = "RECEIVED_FILE_BETS",
    ERROR_FILE_BETS = "ERROR_FILE_BETS",
}

export type requestFileBetAction = {
    type: FileBetActionType.REQUEST_FILE_BETS;
    payload: {
        groupBetId: string;
        placedAt: string;
    };
};

export type receiveFileBetAction = {
    type: FileBetActionType.RECEIVED_FILE_BETS;
    payload: Array<LegacyFileBet>;
};

export type errorFileBetAction = {
    type: FileBetActionType.ERROR_FILE_BETS;
    payload: boolean;
};

export interface IAction<T> {
    type: FileBetActionType;
    payload: T;
}

export type LegacyFileBet = {
    serialNumber: string;
    payout?: Payout;
    systemCost: number;
    betTime: string;
    fee: number;
};

export type Payout = {
    payoutAmount?: number;
    refundAmount?: number;
    payFee?: number;
    refundFee?: number;
    boostPayoutAmount?: number;
    payoutStatus: PayoutStatus;
};

export enum PayoutStatus {
    PAID,
    PENDING,
    PRELIMINARY,
}

import {select, takeLatest} from "redux-saga/effects";
import {deprecated_logEvent} from "@atg-shared/analytics";
import {RECEIVE_USER} from "@atg-global-shared/user/userActionTypes";
import * as UserSettingsSelectors from "./userSettingsSelectors";
import {ACTIVATE_PRO_START_PAGE, DEACTIVATE_PRO_START_PAGE} from "./userSettingsActions";

export function* logUserSettingsAnalytics() {
    const isProPageActive: boolean = yield select(UserSettingsSelectors.isProPageActive);
    deprecated_logEvent({
        view_pro: isProPageActive ? "on" : "off",
    });
}

function* userAnalyticsSaga() {
    yield takeLatest(
        [RECEIVE_USER, ACTIVATE_PRO_START_PAGE, DEACTIVATE_PRO_START_PAGE],
        logUserSettingsAnalytics,
    );
}

export default userAnalyticsSaga;

import {find} from "lodash";
import {createSelector} from "reselect";
import {DepositMethods, CardStatuses} from "@atg-payment-shared/deposit-utils";
import type {GlobalState, DepositOption} from "../domainTypes";

const getDepositState = (state: GlobalState) => state.payment.deposit;

export const statusMessage = createSelector(
    getDepositState,
    (state) => state.statusMessage,
);

export const options = createSelector(getDepositState, (state) => state.options);

export const cardOptions = createSelector(options, (state) => state.cardOptions);

export const swishDirectOption = createSelector(
    options,
    (state) => state.swishDirectOption,
);

export const trustlyOption = createSelector(options, (state) => state.trustlyOption);

export const directDebitOption = (): DepositOption => ({
    id: "directDebit",
    description: "",
});

export const newCardOption = createSelector(
    cardOptions,
    (state) => find(state, {id: DepositMethods.newCard}) || null,
);

export const existingCardOption = createSelector(
    cardOptions,
    (state) => find(state, {id: DepositMethods.existingCard}) || null,
);

export const isLoading = createSelector(getDepositState, (state) => state.isLoading);

export const isPending = createSelector(getDepositState, (state) => state.isPending);

export const isBlocked = createSelector(getDepositState, (state) => state.isBlocked);

export const selectedDepositAmount = createSelector(
    getDepositState,
    (state) => state.amount,
);

export const hasExpiredCard = createSelector(existingCardOption, (card) =>
    card ? card.status === CardStatuses.EXPIRED : false,
);

export const selectedOption = createSelector(
    getDepositState,
    (state) => state.selectedOption,
);

export const isDirectPayment = createSelector(selectedOption, (selectedMethod) =>
    selectedMethod
        ? selectedMethod.id === DepositMethods.swish ||
          selectedMethod.id === DepositMethods.existingCard
        : true,
);

// Todo: Replace with viewState
export const isDepositSuccess = (state: GlobalState): boolean => {
    const status = statusMessage(state);
    if (!status) return false;

    return status.id === "payin-success";
};

export const depositIframe = createSelector(
    getDepositState,
    (state) => state.depositIframe,
);

export const orderIdForDepositIframe = createSelector(depositIframe, (state) =>
    state ? state.orderId : null,
);

export const orderIdSwishDirect = createSelector(getDepositState, (state) =>
    state ? state.swishDirectOrderId : null,
);

// if deposit in iframe has been started and not finished yet
export const isDepositIframeInProgress = createSelector(
    orderIdForDepositIframe,
    isLoading,
    isPending,
    (orderId, loading, pending) => Boolean(orderId) && !loading && pending,
);

export const successfullPaymentMethodId = createSelector(
    getDepositState,
    (state) => state.successfullPaymentMethodId,
);

export const depositWithoutCvc = createSelector(
    selectedOption,
    (selectedMethod) => selectedMethod?.id === DepositMethods.existingCard,
);

export const depositWithSsnValidation = createSelector(
    selectedOption,
    (selectedMethod) => selectedMethod?.id === DepositMethods.swish,
);

export const swishDirectStep = createSelector(
    getDepositState,
    (state) => state.swishDirectStep,
);

export const swishDirectPhoneNumber = createSelector(
    getDepositState,
    (state) => state.swishDirectPhoneNumber,
);

export const swishDirectSelected = createSelector(
    selectedOption,
    swishDirectOption,
    (method, swish) => method === swish,
);

export const gameInfo = createSelector(getDepositState, (state) => state.gameInfo);

import {isString} from "lodash";
import {trackEvent} from "@atg-shared/analytics";
/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
export const LegacyOK = {
    code: "ok",
    isLoading: false,
    error: false,
};

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
export const LegacyLoading = {
    code: "loading",
    isLoading: true,
    error: false,
};

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
export const LOADING = {
    code: "loading",
    isLoading: true,
};

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
export const isLoading = (status) => status && status.code === "loading";

/**
 * @todo consider using `atg-domain/apiMessages.js` instead
 */
const DEFAULT_MESSAGES = {
    "0": "Anslutningen till servern bröts. Kontrollera nätverket.",
    "400": "Ett oförutsett tekniskt fel har inträffat",
    "401": "Inloggning krävs för att utföra operationen.",
    "404": "Resursen du söker efter kunde ej hittas.",
    "500": "Ett oförutsett tekniskt fel har inträffat",
    "503": "Tjänsten är inte tillgänglig just nu, försök igen senare.",
};

/**
 * @todo consider using `atg-domain/apiMessages.js` instead
 */
const getMessageFromResponse = (response) => {
    if (isString(response)) {
        return response.toString();
    }
    if (response.data && response.data.errorMessage) {
        return response.data.errorMessage;
    }
    if (response.responseJSON && response.responseJSON.errorMessage) {
        return response.responseJSON.errorMessage;
    }
    return null;
};

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
const deprecated_getResponseStatus = (response, messages = DEFAULT_MESSAGES) => {
    const httpStatus =
        response.status === undefined
            ? response.meta && response.meta.code
            : response.status;

    const message =
        messages[httpStatus] ||
        getMessageFromResponse(response) ||
        DEFAULT_MESSAGES[httpStatus] ||
        DEFAULT_MESSAGES[500];

    const status = {
        code: "failed",
        isLoading: false,
        error: {
            httpCode: httpStatus,
            message,
        },
    };

    return status;
};

/**
 * @deprecated this way of handling loading states is superseeded by `atg-fetch-redux`
 */
export const getStatusFromResponse = (response, messages = DEFAULT_MESSAGES) => {
    const status = deprecated_getResponseStatus(response, messages);
    trackEvent({
        event: "fetchDataError",
        userError: `${status.error.message}, httpCode:${status.error.httpCode}`,
    });
    return {
        ...status,
        error: {
            ...status.error,
            reason: response.data?.reason,
        },
    };
};

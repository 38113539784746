import root from "window-or-global";
import type {ApolloLink, NormalizedCacheObject} from "@apollo/client";
import {ApolloClient, createHttpLink, from, InMemoryCache} from "@apollo/client";
import type {Dispatch} from "redux";
import {isApp} from "@atg-shared/system";
import Features, {apolloDevTools} from "@atg-shared/client-features";
import {authLink, businessHeadersLink, errorLink} from "./apolloLinks";

type Vertical = "horse";

declare global {
    // eslint-disable-next-line vars-on-top, no-underscore-dangle, no-var
    var _horseApolloClient: ApolloClient<NormalizedCacheObject> | undefined;
}

interface CreateApolloClientOptions {
    authLink: boolean; // To add or not the functionality of getting the auth token, true by default. Useful to disable for tests.
    businessHeadersLink: boolean; // To add or not the functionality of getting the business headers, true by default. Useful to disable for tests.
}

export default class ApolloClientInstance {
    static getClient(
        dispatch: Dispatch,
        vertical: Vertical = "horse",
        options: CreateApolloClientOptions = {authLink: true, businessHeadersLink: true},
    ) {
        const name = this.getClientName(vertical);

        if (!this.exists(vertical)) {
            const links = [
                options.authLink ? authLink : undefined,
                options.businessHeadersLink ? businessHeadersLink : undefined,
                errorLink(dispatch),
                createHttpLink({
                    // Using the default horse endpoint for now. If you want to use graphql in your team as well, look at Apollo Federation, or if you want to keep it completely separate then look at this https://www.jamalx31.com/tech-posts/using-apollo-with-multiple-graphql-endpoints
                    uri: window.clientConfig?.horse?.graphqlHorse,
                }),
            ].filter((link): link is ApolloLink => link !== undefined);

            root[name] = new ApolloClient({
                link: from(links),
                // probably possible to inject in runtime, like how we do with sagas. Will help release the microfrontends independently, without frame.
                cache: new InMemoryCache(),
                connectToDevTools:
                    process.env.NODE_ENV === "development" ||
                    Features.isEnabled(apolloDevTools) ||
                    // @ts-ignore
                    (isApp && global.__DEV__),
            });
        }
        return root[name]!;
    }

    static async clearCache(vertical: Vertical) {
        if (this.exists(vertical)) {
            await root[this.getClientName(vertical)]!.resetStore();
        }
    }

    private static exists(vertical: Vertical) {
        return !!root[this.getClientName(vertical)];
    }

    private static getClientName(vertical: Vertical): `_${Vertical}ApolloClient` {
        return `_${vertical}ApolloClient`;
    }
}

import type {AuthActions} from "@atg-shared/auth";
import {frameAction, broadcastAction} from "atg-store-addons";

export const START_LOGIN_FLOW = "START_LOGIN_FLOW";
export const START_REGISTER_FLOW = "START_REGISTER_FLOW";
export const FINISH_MEMBER_FLOW = "FINISH_MEMBER_FLOW";
export const CANCELLED_LOGIN_FLOW = "CANCELLED_LOGIN_FLOW";
export const MEMBER_REGISTER_FINISHED = "MEMBER_REGISTER_FINISHED";
export const MEMBER_REGISTER_CANCEL = "MEMBER_REGISTER_CANCEL";
export const MEMBER_REGISTER_CURITY_CREATE_SUCCESS =
    "MEMBER_REGISTER_CURITY_CREATE_SUCCESS";

export const LOGIN_NORMAL = "LOGIN_NORMAL";
export const LOGIN_COOKIE = "LOGIN_COOKIE";
export const PRO_ACCOUNT = "PRO_ACCOUNT";

export type Role = typeof LOGIN_NORMAL | typeof LOGIN_COOKIE | typeof PRO_ACCOUNT;
export type Roles = Role[];

export type StartRegisterFlowAction = {
    type: typeof START_REGISTER_FLOW;
    payload: AuthActions.MemberFlowOptions;
};

export type FinishMemberFlowAction = {
    type: typeof FINISH_MEMBER_FLOW;
};

export type StartLoginFlowAction = {
    type: typeof START_LOGIN_FLOW;
    payload: AuthActions.MemberFlowOptions;
};

export type LoginFlowCancelledAction = {
    type: typeof CANCELLED_LOGIN_FLOW;
};

export type MemberRegisterCancelAction = {
    type: typeof MEMBER_REGISTER_CANCEL;
};

export type MemberRegisterFinishedAction = {
    type: typeof MEMBER_REGISTER_FINISHED;
};

export type MemberRegisterCurityCreateSuccessAction = {
    type: typeof MEMBER_REGISTER_CURITY_CREATE_SUCCESS;
};

export type Action =
    | AuthActions.StartAuthenticationFlowAction
    | StartLoginFlowAction
    | StartRegisterFlowAction
    | MemberRegisterCancelAction
    | MemberRegisterFinishedAction
    | FinishMemberFlowAction
    | LoginFlowCancelledAction;

export const startLoginFlow = (
    options: AuthActions.MemberFlowOptions = {},
): StartLoginFlowAction =>
    frameAction({
        type: START_LOGIN_FLOW,
        payload: options,
    });

export const cancelLoginFlow = (): LoginFlowCancelledAction =>
    broadcastAction({
        type: CANCELLED_LOGIN_FLOW,
    });

export const startRegisterFlow = (
    options: AuthActions.MemberFlowOptions = {},
): StartRegisterFlowAction =>
    frameAction({
        type: START_REGISTER_FLOW,
        payload: options,
    });

export const memberRegisterCancel = (): MemberRegisterCancelAction => ({
    type: MEMBER_REGISTER_CANCEL,
});

export const memberRegisterFinished = (): MemberRegisterFinishedAction =>
    broadcastAction({
        type: MEMBER_REGISTER_FINISHED,
    });

export const finishMemberFlow = (): FinishMemberFlowAction =>
    broadcastAction({
        type: FINISH_MEMBER_FLOW,
    });

export const memberRegisterCurityCreateSuccess =
    (): MemberRegisterCurityCreateSuccessAction => ({
        type: MEMBER_REGISTER_CURITY_CREATE_SUCCESS,
    });

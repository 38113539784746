import {fetchAuthorized} from "@atg-shared/auth";
import type {ChangePasswordParams} from "./changePasswordActions";

export const CHANGE_PASSWORD =
    "/services/tokenized-proxy/member-auth/api/v1/member/password";

export const changePassword = (data: ChangePasswordParams) =>
    fetchAuthorized(CHANGE_PASSWORD, {
        method: "PUT",
        body: JSON.stringify(data),
    });

import {FETCH, call} from "@atg-shared/fetch-redux";
import {type FetchAction} from "@atg-shared/fetch-types";
import * as HarryAPI from "./harryAPI";

// Harry constants
export const RECEIVE_HARRY_BAGS = "RECEIVE_HARRY_BAGS" as const;
export const REQUEST_HARRY_BAGS = "REQUEST_HARRY_BAGS" as const;
export const RECEIVE_HARRY_SUBSCRIPTIONS = "RECEIVE_HARRY_SUBSCRIPTIONS" as const;
export const REQUEST_HARRY_SUBSCRIPTIONS = "REQUEST_HARRY_SUBSCRIPTIONS" as const;
export const RECEIVE_SIMPLE_HARRY = "RECEIVE_SIMPLE_HARRY" as const;
export const RECEIVE_HARRY_TOMORROW = "RECEIVE_HARRY_TOMORROW" as const;
export const REQUEST_SIMPLE_HARRY = "REQUEST_SIMPLE_HARRY" as const;
export const REQUEST_HARRY_TOMORROW = "REQUEST_HARRY_TOMORROW" as const;
export const RECEIVE_HARRY_CAMPAIGN = "RECEIVE_HARRY_CAMPAIGN" as const;
export const REQUEST_HARRY_CAMPAIGN = "REQUEST_HARRY_CAMPAIGN" as const;

// Harry Subscription as optional choice constants
export const REQUEST_HARRY_SUBSCRIPTION_AVAILABLE =
    "REQUEST_HARRY_SUBSCRIPTION_AVAILABLE" as const;
export const RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE =
    "RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE" as const;
export const SET_HARRY_SUBSCRIPTION_SELECTED = "SET_HARRY_SUBSCRIPTION_SELECTED" as const;

export type SimpleHarryFetchAction = FetchAction<
    typeof REQUEST_SIMPLE_HARRY,
    typeof RECEIVE_SIMPLE_HARRY,
    any
>;

export type HarryTommorowFetchAction = FetchAction<
    typeof REQUEST_HARRY_TOMORROW,
    typeof RECEIVE_HARRY_TOMORROW,
    any
>;

export type HarryFilterFromToFetchAction = FetchAction<
    typeof REQUEST_HARRY_CAMPAIGN,
    typeof RECEIVE_HARRY_CAMPAIGN,
    any
>;

export type HarryBagsFetchAction = FetchAction<
    typeof REQUEST_HARRY_BAGS,
    typeof RECEIVE_HARRY_BAGS,
    any
>;

export type HarrySubscriptionsFetchAction = FetchAction<
    typeof REQUEST_HARRY_SUBSCRIPTIONS,
    typeof RECEIVE_HARRY_SUBSCRIPTIONS,
    any
>;

export type Action =
    | SimpleHarryFetchAction
    | HarryTommorowFetchAction
    | HarryFilterFromToFetchAction
    | HarryBagsFetchAction
    | HarrySubscriptionsFetchAction;

/**
 * Fetch all upcoming Harry Boy games
 *
 * NOTE: The backend has a built-in filter to prevent the same game type from appearing several
 * times, for some game types.
 */
export const fetchSimpleHarry = (): SimpleHarryFetchAction => ({
    type: FETCH,
    payload: {
        callApi: call(HarryAPI.fetchSimpleHarry),
        requestAction: REQUEST_SIMPLE_HARRY,
        receiveAction: RECEIVE_SIMPLE_HARRY,
    },
});

// Harry Tomorrow
export const fetchHarryTomorrow = (): HarryTommorowFetchAction => ({
    type: FETCH,
    payload: {
        callApi: call(HarryAPI.fetchHarryTomorrow),
        requestAction: REQUEST_HARRY_TOMORROW,
        receiveAction: RECEIVE_HARRY_TOMORROW,
    },
});

// Harry filtered From To
export const fetchHarryFilterFromTo = (): HarryFilterFromToFetchAction => ({
    type: FETCH,
    payload: {
        callApi: call(HarryAPI.fetchHarryFromTo),
        requestAction: REQUEST_HARRY_CAMPAIGN,
        receiveAction: RECEIVE_HARRY_CAMPAIGN,
    },
});

// Harry bags
export const fetchHarryBags = (): HarryBagsFetchAction => ({
    type: FETCH,
    payload: {
        callApi: call(HarryAPI.fetchHarryBags),
        requestAction: REQUEST_HARRY_BAGS,
        receiveAction: RECEIVE_HARRY_BAGS,
    },
});

// Harry subscriptions
export const fetchHarrySubscriptions = (): HarrySubscriptionsFetchAction => ({
    type: FETCH,
    payload: {
        callApi: call(HarryAPI.fetchHarrySubscriptions),
        requestAction: REQUEST_HARRY_SUBSCRIPTIONS,
        receiveAction: RECEIVE_HARRY_SUBSCRIPTIONS,
    },
});

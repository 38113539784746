import {put, call, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {AnyAction} from "redux";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {MODAL_CLOSE} from "atg-modals/modalActionTypes";
import type {CloseAction} from "atg-modals/modalActions";
import {showChangePasswordModal, closeChangePasswordModal} from "atg-modals/modalActions";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import {
    changePasswordSuccess,
    changePasswordError,
    changePasswordClear,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_PASSWORD_INIT,
} from "./changePasswordActions";
import type {
    ChangePasswordActions,
    ChangePasswordSuccessAction,
    ChangePasswordAction,
} from "./changePasswordActions";
import * as ChangePasswordApi from "./changePasswordApi";

function isSuccessResponse(
    action: ChangePasswordActions | AnyAction,
): action is ChangePasswordSuccessAction {
    return (
        action.type === CHANGE_PASSWORD_RESPONSE &&
        (!("error" in action) || !action.error)
    );
}

export function* changePasswordLoginFlow(
    username: string | undefined | null,
    login: (username?: string | null, newPassword?: string) => SagaIterator,
): SagaIterator {
    const successOrAbort = (
        action: ChangePasswordActions | MemberActions.LoginFlowCancelledAction,
    ) => isSuccessResponse(action) || action.type === MemberActions.CANCELLED_LOGIN_FLOW;

    // wait for success
    // @ts-ignore
    const changeTempPasswordAction = yield take(successOrAbort);
    yield put(changePasswordClear());
    if (changeTempPasswordAction.type === MemberActions.CANCELLED_LOGIN_FLOW) return null;

    try {
        // attempt new login (could this be made recursive?)
        const {newPassword} = changeTempPasswordAction.payload;
        const loginResponse = yield call<typeof login>(login, username, newPassword);

        // show success toast
        yield put(
            ToastActions.showToast({
                type: AtgAlertTypes.SUCCESS,
                message: "Lösenordsbytet genomfördes.",
            }),
        );

        return loginResponse; // payload = {user, roles}
    } catch (e: unknown) {
        return null;
    }
}

function* changePasswordFlow(action: ChangePasswordAction): SagaIterator {
    let response;

    try {
        response = yield call(ChangePasswordApi.changePassword, action.params);
    } catch (error: unknown) {
        const message = "Ditt nuvarande lösenord stämmer inte.";
        yield put(changePasswordError({message}));
        return;
    }

    yield put(changePasswordSuccess({...response.data, ...action.params})); // keep params so it can be used in loginSaga
}

function* changePasswordModalFlow(): SagaIterator {
    yield put(changePasswordClear());
    yield put(showChangePasswordModal());

    const successOrAbort = (action: ChangePasswordActions | CloseAction) =>
        isSuccessResponse(action) ||
        (action.type === MODAL_CLOSE && action.payload === "changePasswordModal");

    // @ts-ignore
    const action = yield take(successOrAbort);
    if (action.type === MODAL_CLOSE) return;

    yield put(closeChangePasswordModal());
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.SUCCESS,
            message: "Lösenordsbytet genomfördes.",
        }),
    );
}

export default function* changePasswordSaga(): SagaIterator {
    yield takeLatest(CHANGE_PASSWORD, changePasswordFlow);
    yield takeLatest(CHANGE_PASSWORD_INIT, changePasswordModalFlow);
}

import _ from "lodash";
import mergePlain from "./mergePlain";

const nonBreakingSpace = "\xA0";

export const escapeRegExp = (str: string) => {
    if (str == null) return "";
    return String(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, "\\$1");
};

export const addThousandsSeparator = (str: string | number) =>
    String(str).replace(/\B(?=(\d{3})+(?!\d))/g, nonBreakingSpace);

export const slugify = (str: string) => _.kebabCase(_.deburr(str));

export const slugifyNoDash = (str: string) =>
    _.deburr(str)
        .replace(/[\s-]+/g, "")
        .toLowerCase();

export const formatAmount = (amount: number | string | null) => {
    if (typeof amount === "string" || (!amount && amount !== 0))
        return amount?.toString() || "";

    let formatted = amount;
    let negative;
    if (formatted && formatted < 0) {
        negative = true;
        formatted = Math.abs(formatted);
    }

    const amountString = formatted && formatted.toString();
    const formattedAmount = amountString && addThousandsSeparator(amountString);

    if (negative && formattedAmount) return `-${formattedAmount}`;
    return `${formattedAmount}`;
};

export const oddsToString = (odds?: number | null, scratchedMarker?: string | null) => {
    if (odds === undefined || odds === null) return "";

    if (odds === 0) {
        if (scratchedMarker) return scratchedMarker;
        return "EJ";
    }

    const intPart = Math.floor(odds / 100);
    const decimal = odds % 100;
    return `${formatAmount(intPart)},${`0${decimal}`.slice(-2)}`;
};

export const formatDecimalAmount = (amount: number) => {
    const intPart = Math.floor(amount / 100);
    const decimal = amount % 100;

    return `${formatAmount(intPart)},${`0${decimal}`.slice(-2)}`;
};

export const formatNumber = (
    number: number,
    options: {
        fractionDigits?: number | null;
        minFractionDigits?: number | null;
        maxFractionDigits?: number | null;
    } = {},
) => {
    mergePlain(options, {
        minFractionDigits: options.fractionDigits,
        maxFractionDigits: options.fractionDigits,
    });
    const minFractionDigits = options.minFractionDigits || 0;
    let maxFractionDigits =
        options.maxFractionDigits === 0 ? 0 : options.maxFractionDigits || 20;
    maxFractionDigits = Math.max(minFractionDigits, maxFractionDigits);
    const precision = (String(number).split(".")[1] || "").length;
    const noOfFractionDigits = Math.min(
        maxFractionDigits,
        Math.max(minFractionDigits, precision),
    );
    const multiplier = 10 ** noOfFractionDigits;
    const roundedNumber = Math.round(Math.abs(number * multiplier)) / multiplier;
    const sign = number < 0 ? -1 : 1;
    const integerPartAsString = formatAmount(Math.floor(roundedNumber) * sign);
    if (noOfFractionDigits > 0) {
        const decimalPartAsSting = String(roundedNumber).split(".")[1] || "";
        return `${integerPartAsString},${`${decimalPartAsSting}00000000000000000000`.slice(
            0,
            noOfFractionDigits,
        )}`;
    }
    return integerPartAsString;
};

// http://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
export const pad = (n: string, width: number, z = "0") => {
    const nString = String(n);
    return nString.length >= width
        ? nString
        : new Array(width - nString.length + 1).join(z) + nString;
};

export const capitaliseFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatTSN = (num?: string | null) => {
    if (!num) return "";

    const array = num.match(/.{1,4}/g);
    return (array || []).join(nonBreakingSpace);
};

export const formatReceiptCode = (code?: string | null) => {
    if (!code) return "";

    const array = code.match(/(.{3})/g);
    return (array || []).join(" ");
};

const NON_BREAKING_SPACE = "\xA0";

export const toNonBreakingString = (str: string) =>
    str.split(" ").join(NON_BREAKING_SPACE);

// TODO: Remove when this has been fixed in gaming system
export const formatWinning = (winning: string, gameType?: string) => {
    if (!_.isString(winning)) return null;

    const divider = gameType === "tvilling" ? "/" : "-";
    return winning.replace(/\//g, divider);
};

export const formatDataTestId = (...arr: Array<string>) => {
    if (!arr) return "";
    return arr.join("-").replace(/\s+/g, "-").toLowerCase();
};

/**
 * formats numbers into a string of amount + unit.
 * used for Jackpots
 *
 * ex: 500 000 = "500 000 kr", 1 000 000 = "1 Miljon",  1 500 000 = "1,5 Miljoner", 10 000 = "10 Tusen" etc...
 */
export const formatJackpotAmount = (value: number) => {
    let unit: string;

    if (value < 1000000) {
        return `${value.toLocaleString("sv-SE")} kr`;
    }
    unit = "Miljon";
    const roundValue = 100000;
    const roundedValue = Math.round(value / roundValue);
    if (roundedValue > 10) {
        unit = "Miljoner";
    }

    const formattedAmount = (roundedValue / 10).toString().replace(".", ",");

    return `${formattedAmount} ${unit}`;
};

export const formatSpeechBubbleJackpotAmount = (value: number) => {
    let unit: string;
    let roundValue: number;
    let roundedValue: number;

    if (value < 1000000) {
        unit = "Tusen";
        // roundValue is one order of magnitude smaller than the desired unit,
        // ensuring precision to one decimal place.
        roundValue = 100;
        roundedValue = Math.round(value / roundValue);
    } else {
        unit = "Miljon";
        roundValue = 100000;
        roundedValue = Math.round(value / roundValue);
        if (roundedValue > 10) {
            unit = "Miljoner";
        }
    }

    const formattedAmount = (roundedValue / 10).toString().replace(".", ",");

    return `${formattedAmount} ${unit}`;
};

import Cookie from "js-cookie";

export class CookieStorage {
    prefix: string;

    options?: {domain: string};

    /**
     * Class for interacting with the browser cookie storage
     * @param prefix : prefix used for all cookie names
     * @param domain : the domain that the cookies should target. Setting i.e. 'atg.se' means that it will be shared between both 'atg.se' and 'tillsammans.atg.se' domains. Read more here: https://github.com/js-cookie/js-cookie#domain
     */
    constructor(prefix = "", domain?: string) {
        this.prefix = prefix;
        this.options = domain ? {domain} : undefined;
    }

    private getPrefixedKey(key: string) {
        return `${this.prefix}_${key}`;
    }

    setItem(key: string, value: string) {
        Cookie.set(this.getPrefixedKey(key), value, this.options);
    }

    getItem(key: string): string {
        return Cookie.get(this.getPrefixedKey(key)) || "";
    }

    // eslint-disable-next-line class-methods-use-this
    getUnPrefixedItem(key: string) {
        return Cookie.get(key);
    }

    removeItem(key: string) {
        Cookie.remove(this.getPrefixedKey(key), this.options);
    }
}

export default CookieStorage;

import * as storage from "@atg-shared/storage";
import * as QuickplaySelectors from "./quickplaySelectors";
import type {QuickplayState} from "./quickplayReducer";

export const SELECT_QUICK_PLAY_TRACK = "quickplay/SELECT_QUICK_PLAY_TRACK";
export const SELECT_QUICK_PLAY_RACE = "quickplay/SELECT_QUICK_PLAY_RACE";
export const SELECT_QUICK_PLAY_GAME_TYPE = "quickplay/SELECT_QUICK_PLAY_GAME_TYPE";
export const ACTIVATE_AUTOMATIC_TRACK_SWITCHER =
    "quickplay/ACTIVATE_AUTOMATIC_TRACK_SWITCHER";
export const SET_LIVE_BETS_VISITED = "quickplay/SET_LIVE_BETS_VISITED";
export const SET_LIVE_BETS_TOGGLED = "quickplay/SET_LIVE_BETS_TOGGLED";
export const SELECT_LIVE_TRACK = "quickplay/SELECT_LIVE_TRACK";
export const SELECT_CURRENT_LIVE_TRACK = "quickplay/SELECT_CURRENT_LIVE_TRACK";

export type GlobalQuickplayState = {
    horse: {
        quickplay: QuickplayState;
    };
};

type SelectLiveTrackAction = {
    type: typeof SELECT_LIVE_TRACK;
    payload: {
        selectedRaceId?: string;
        selectedTrack?: number;
        currentGameType?: string | null;
    };
};

type SelectTrackAction = {
    type: typeof SELECT_QUICK_PLAY_TRACK;
    payload: number | null | undefined;
};

type SelectQuickplayRaceAction = {
    type: typeof SELECT_QUICK_PLAY_RACE;
    payload: {
        gameType?: string;
        raceId?: string;
    };
};

type SelectQuickplayGameTypeAction = {
    type: typeof SELECT_QUICK_PLAY_GAME_TYPE;
    payload: string | null | undefined;
};

type LiveBetsToggledAction = {
    type: typeof SET_LIVE_BETS_TOGGLED;
    payload: boolean | null | undefined;
};

type ActivateAutomaticSwitcherAction = {
    type: typeof ACTIVATE_AUTOMATIC_TRACK_SWITCHER;
    payload: boolean;
};

type LiveBetsVisitedAction = {
    type: typeof SET_LIVE_BETS_VISITED;
    payload: boolean;
};

export type QuickplayAction =
    | SelectTrackAction
    | SelectQuickplayRaceAction
    | SelectQuickplayGameTypeAction
    | ActivateAutomaticSwitcherAction
    | LiveBetsVisitedAction
    | LiveBetsToggledAction
    | SelectLiveTrackAction;

export function selectTrack(trackId: number) {
    return {
        type: SELECT_QUICK_PLAY_TRACK,
        payload: trackId,
    };
}

export function selectRace(raceId: string | null | undefined, gameType?: string) {
    return {
        type: SELECT_QUICK_PLAY_RACE,
        payload: {
            raceId,
            gameType,
        },
    };
}

export function selectGameType(gameType: string) {
    return {
        type: SELECT_QUICK_PLAY_GAME_TYPE,
        payload: gameType,
    };
}

export function activateAutomaticTrackSwitcher(toggle: boolean) {
    return {
        type: ACTIVATE_AUTOMATIC_TRACK_SWITCHER,
        payload: toggle,
    };
}

export const selectLiveTrack = (
    selectedRaceId?: string,
    selectedTrack?: number,
    currentGameType?: string | null,
) => ({
    type: SELECT_LIVE_TRACK,
    payload: {
        selectedRaceId,
        selectedTrack,
        currentGameType,
    },
});

/**
 * Select the current live track on ATG Live
 */
export function selectCurrentLiveTrack() {
    return {
        type: SELECT_CURRENT_LIVE_TRACK,
    };
}

export function setLiveBetsVisited() {
    return (
        dispatch: (arg0: {type: string; payload: boolean}) => Record<string, unknown>,
        getState: () => GlobalQuickplayState,
    ) => {
        const state = getState();
        if (!QuickplaySelectors.isLiveBetsVisited(state)) {
            storage.setItem("liveBetsVisited", "true");
            dispatch({
                type: SET_LIVE_BETS_VISITED,
                payload: true,
            });
        }
    };
}

export function setLiveBetsToggled(toggled: boolean) {
    return {
        type: SET_LIVE_BETS_TOGGLED,
        payload: toggled,
    };
}

import type {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";

export const LOADING_STARTED = "LOADING_STARTED";
export const LOADING_FINISHED = "LOADING_FINISHED";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const HANDLE_FAVORITE_HORSE = "HANDLE_FAVORITE_HORSE";
export const ADD_FAVORITE_HORSE = "ADD_FAVORITE_HORSE";
export const STORE_FAVORITE_HORSE = "STORE_FAVORITE_HORSE";
export const STORE_ALL_FAVORITE_HORSES = "STORE_ALL_FAVORITE_HORSES";
export const REMOVE_FAVORITE_HORSE_FROM_STARTLIST =
    "REMOVE_FAVORITE_HORSE_FROM_STARTLIST";
export const REMOVE_FAVORITE_HORSE_FROM_FAVORITES_PAGE =
    "REMOVE_FAVORITE_HORSE_FROM_FAVORITES_PAGE";
export const REMOVE_FAVORITE_HORSE_FROM_STORE = "REMOVE_FAVORITE_HORSE_FROM_STORE";
export const GET_ONLY_FAVORITE_HORSES = "GET_ONLY_FAVORITE_HORSES";
export const GET_FAVORITE_HORSES_WITH_ALL_INFO = "GET_FAVORITE_HORSES_WITH_ALL_INFO";
export const GET_FAVORITES_PREFERENCES = "GET_FAVORITES_PREFERENCES";
export const SET_FAVORITES_PREFERENCES = "SET_FAVORITES_PREFERENCES";
export const STORE_FAVORITES_PREFERENCES = "STORE_FAVORITES_PREFERENCES";
export const SHOW_HORSE_FAVORITES_SETTINGS_MODAL = "SHOW_HORSE_FAVORITES_SETTINGS_MODAL";
export const CLOSE_HORSE_FAVORITES_SETTINGS_MODAL =
    "CLOSE_HORSE_FAVORITES_SETTINGS_MODAL";
export const START_NOTIFICATION_PREFERENCES = "START_NOTIFICATION_PREFERENCES";

export enum NotificationMethods {
    NotSet = "NOT_SET",
    None = "NONE",
    Sms = "SMS",
    Email = "EMAIL",
    Push = "PUSH",
}

export type NotificationMethod = NotificationMethods;

export type Preferences = {
    notificationMethod: NotificationMethod | undefined;
};

export type UpcomingStart = {
    startNumber: number;
    raceNumber: number;
    raceId: string;
    trackId: number;
    trackName: string;
    countryCode: string;
    startTime: string;
};

export type LogoutFinishedAction = {
    type: typeof LOGOUT_FINISHED;
};

export type LoadingStarted = {
    type: typeof LOADING_STARTED;
};

export type LoadingFinished = {
    type: typeof LOADING_FINISHED;
};

export type SetError = {
    type: typeof SET_ERROR;
};

export type ClearError = {
    type: typeof CLEAR_ERROR;
};

export type FavoriteHorse = {
    id: number;
    name?: string;
    upcomingStarts?: Array<UpcomingStart>;
    sport?: "trot" | "gallop";
    created?: string;
};

export type HandleFavoriteHorseAction = {
    type: typeof HANDLE_FAVORITE_HORSE;
    horse: FavoriteHorse;
};

export type AddFavoriteHorseAction = {
    type: typeof ADD_FAVORITE_HORSE;
    horse: FavoriteHorse;
};

export type StoreFavoriteHorseAction = {
    type: typeof STORE_FAVORITE_HORSE;
    horse: FavoriteHorse;
};

export type StoreAllFavoriteHorsesAction = {
    type: typeof STORE_ALL_FAVORITE_HORSES;
    horses: Array<FavoriteHorse>;
};

export type RemoveFavoriteHorseFromFavoritesPageAction = {
    type: typeof REMOVE_FAVORITE_HORSE_FROM_FAVORITES_PAGE;
    horseId: number;
};

export type RemoveFavoriteHorseFromStartlistAction = {
    type: typeof REMOVE_FAVORITE_HORSE_FROM_STARTLIST;
    horseId: number;
};

export type RemoveFavoriteHorseFromStoreAction = {
    type: typeof REMOVE_FAVORITE_HORSE_FROM_STORE;
    horseId: number;
};

export type GetOnlyFavoriteHorsesAction = {
    type: typeof GET_ONLY_FAVORITE_HORSES;
};

export type GetFavoriteHorsesWithAllInfoAction = {
    type: typeof GET_FAVORITE_HORSES_WITH_ALL_INFO;
};

export type GetFavoritesPreferencesAction = {
    type: typeof GET_FAVORITES_PREFERENCES;
};

export type SetFavoritesPreferencesAction = {
    type: typeof SET_FAVORITES_PREFERENCES;
    preferences: Preferences;
};

export type StoreFavoritesPreferencesAction = {
    type: typeof STORE_FAVORITES_PREFERENCES;
    preferences: Preferences;
};

export type CloseHorseFavoritesSettingsModal = {
    type: typeof CLOSE_HORSE_FAVORITES_SETTINGS_MODAL;
};

export type ShowHorseFavoritesSettingsModal = {
    type: typeof SHOW_HORSE_FAVORITES_SETTINGS_MODAL;
};

export type StartNotificationPreferences = {
    type: typeof START_NOTIFICATION_PREFERENCES;
    horse: FavoriteHorse;
};

export const loadingStarted = (): LoadingStarted => ({
    type: LOADING_STARTED,
});

export const loadingFinished = (): LoadingFinished => ({
    type: LOADING_FINISHED,
});

export const setError = (): SetError => ({
    type: SET_ERROR,
});

export const clearError = (): ClearError => ({
    type: CLEAR_ERROR,
});

export const handleFavoriteHorse = (horse: FavoriteHorse): HandleFavoriteHorseAction => ({
    type: HANDLE_FAVORITE_HORSE,
    horse,
});

export const addFavoriteHorse = (horse: FavoriteHorse): AddFavoriteHorseAction => ({
    type: ADD_FAVORITE_HORSE,
    horse,
});

export const getOnlyFavoriteHorses = (): GetOnlyFavoriteHorsesAction => ({
    type: GET_ONLY_FAVORITE_HORSES,
});

export const getFavoriteHorsesWithAllInfo = (): GetFavoriteHorsesWithAllInfoAction => ({
    type: GET_FAVORITE_HORSES_WITH_ALL_INFO,
});

export const storeFavoriteHorse = (horse: FavoriteHorse): StoreFavoriteHorseAction => ({
    type: STORE_FAVORITE_HORSE,
    horse,
});

export const storeAllFavoriteHorses = (
    horses: Array<FavoriteHorse>,
): StoreAllFavoriteHorsesAction => ({
    type: STORE_ALL_FAVORITE_HORSES,
    horses,
});

export const removeFavoriteHorseFromFavoritesPage = (
    horseId: number,
): RemoveFavoriteHorseFromFavoritesPageAction => ({
    type: REMOVE_FAVORITE_HORSE_FROM_FAVORITES_PAGE,
    horseId,
});

export const removeFavoriteHorseFromStartlist = (
    horseId: number,
): RemoveFavoriteHorseFromStartlistAction => ({
    type: REMOVE_FAVORITE_HORSE_FROM_STARTLIST,
    horseId,
});

export const removeFavoriteHorseFromStore = (
    horseId: number,
): RemoveFavoriteHorseFromStoreAction => ({
    type: REMOVE_FAVORITE_HORSE_FROM_STORE,
    horseId,
});

export const getFavoritesPreferences = (): GetFavoritesPreferencesAction => ({
    type: GET_FAVORITES_PREFERENCES,
});

export const setFavoritesPreferences = (
    preferences: Preferences,
): SetFavoritesPreferencesAction => ({
    type: SET_FAVORITES_PREFERENCES,
    preferences,
});

export const storeFavoritesPreferences = (
    preferences: Preferences,
): StoreFavoritesPreferencesAction => ({
    type: STORE_FAVORITES_PREFERENCES,
    preferences,
});

export const closeHorseFavoritesSettingsModal = (): CloseHorseFavoritesSettingsModal => ({
    type: CLOSE_HORSE_FAVORITES_SETTINGS_MODAL,
});

export const showHorseFavoritesSettingsModal = (): ShowHorseFavoritesSettingsModal => ({
    type: SHOW_HORSE_FAVORITES_SETTINGS_MODAL,
});

export const startNotificationPreferences = (
    horse: FavoriteHorse,
): StartNotificationPreferences => ({
    type: START_NOTIFICATION_PREFERENCES,
    horse,
});

export type Types =
    | HandleFavoriteHorseAction
    | AddFavoriteHorseAction
    | GetOnlyFavoriteHorsesAction
    | GetFavoriteHorsesWithAllInfoAction
    | RemoveFavoriteHorseFromFavoritesPageAction
    | RemoveFavoriteHorseFromStartlistAction
    | RemoveFavoriteHorseFromStoreAction
    | StoreFavoriteHorseAction
    | StoreAllFavoriteHorsesAction
    | GetFavoritesPreferencesAction
    | SetFavoritesPreferencesAction
    | StoreFavoritesPreferencesAction
    | CloseHorseFavoritesSettingsModal
    | ShowHorseFavoritesSettingsModal
    | StartNotificationPreferences
    | LogoutFinishedAction
    | LoadingStarted
    | LoadingFinished
    | SetError
    | ClearError;

import type {Document} from "@contentful/rich-text-types";
import type {WithSchedule} from "@atg/utils/scheduledItems";
import {RECEIVE_HIGHLIGHTS, FETCH_HIGHLIGHTS_ERROR} from "./highlightsActionTypes";

type HighlightStatus =
    | "bettable"
    | "upcoming"
    | "results"
    | "scheduled"
    | "ongoing"
    | "cancelled";

export type HighlightType = "next" | "today" | "week";

export type Highlight = {
    addOns?: Array<string>;
    id: string;
    startTime: string;
    status: HighlightStatus;
    trackNames: Array<string>;
    type: string;
    url?: string;
    turnover?: number;
    turnoverTimestamp?: string;

    /** the jackpot turnover amount (this does not include the projected total pool size) */
    /**
     * "Jackpot" in the scope of this
     * package refers to **carry-over money from previous game days**.
     * This is different from "Jackpot" in e.g. @atg-content-shared/contentful-hooks/useAllCurrentJackpots.ts,
     * which refers to **estimated total turnover, including carry-over money from previous game days**.
     */
    jackpotAmount?: number;
};

export type Highlights = Record<HighlightType, Highlight | null>;

export enum TeaserType {
    regularTeaser = "regularTeaser",
    promoTeaser = "promoTeaser",
}

export type RegularTeaser = {
    id: string;
    type: TeaserType.regularTeaser;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
    logoDesktop?: string;
    logoMobile?: string;
    bodyTextDesktop: string;
    bodyTextMobile: string;
    linkTextDesktop: string;
    linkTextMobile: string;
    link: string;
};

export type PromoTeaser = {
    id: string;
    type: TeaserType.promoTeaser;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
    highlightTextColor: string;
    bodyTextDesktop: Document;
    bodyTextMobile: Document;
    linkTextDesktop: string;
    linkTextMobile: string;
    link: string;
};

export type TeaserResponse = {
    [TeaserType.regularTeaser]: RegularTeaser;
    [TeaserType.promoTeaser]: Array<WithSchedule<PromoTeaser>> | null;
};

export type ScheduledTeaser = WithSchedule<RegularTeaser | PromoTeaser>;

export type HighlightState = {
    data: Highlights | Record<string, never>;
    error?: boolean;
    contentful: {
        teaser?: Array<ScheduledTeaser>;
        error?: boolean;
    };
};

export type GlobalHighlightsState = {
    horse: {
        highlights: HighlightState;
    };
};

const initialState: HighlightState = {
    data: {},
    contentful: {},
};

type Action = {
    type: string;
    payload?: Highlights | TeaserResponse;
    error?: boolean;
};

const highlights = (state: HighlightState = initialState, action: Action) => {
    const {type, payload} = action;

    switch (type) {
        case RECEIVE_HIGHLIGHTS:
            return {...state, data: payload};

        case FETCH_HIGHLIGHTS_ERROR:
            return {...state, data: {}, error: true};

        default:
            return state;
    }
};

export default highlights;

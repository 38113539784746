import type {SagaIterator} from "redux-saga";
import {call, put, delay} from "redux-saga/effects";
import root from "window-or-global";
import * as Storage from "@atg-shared/storage";
import {
    DepositMessages,
    DepositStorageKeys,
    DepositStatuses,
} from "@atg-payment-shared/deposit-utils";
import * as DepositActions from "../../actions/actions";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    errorMessage,
    initDeposit,
    type DepositResponseError,
} from "../helpers/sagaHelpers";

export function* checkDepositStatusTrustly(): SagaIterator {
    const depositOrderId: string | null = yield call(
        Storage.getItem,
        DepositStorageKeys.depositOrderId,
    );

    if (!depositOrderId) return;

    try {
        const response = yield call(DepositApi.checkStatusTrustly, depositOrderId);
        const {amount, status} = response.data;

        if (
            status === DepositStatuses.CANCELLED ||
            status === DepositStatuses.COMPLETED
        ) {
            yield call(Storage.removeItem, DepositStorageKeys.depositWithTrustlyStarted);
            yield call(Storage.removeItem, DepositStorageKeys.isDepositFlowOnPage);
            yield call(Storage.removeItem, DepositStorageKeys.depositOrderId);
        }
        yield call(statusMessage, status, amount);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}

export function* handleRedirectFlow(payload: Record<string, any>): SagaIterator {
    const openInNewWindow = payload?.openInNewWindow;

    const response = yield call(initDeposit, payload);
    if (!response) return;

    const {orderId, redirectUrl} = response.data;

    yield call(Storage.setItem, DepositStorageKeys.depositOrderId, orderId);

    if (!openInNewWindow) {
        root.location.href = redirectUrl;
        return;
    }

    const windowRef = yield call(
        root.open,
        redirectUrl,
        "_blank",
        "width=1024, height=700, resizable, scrollbars",
    );

    if (!windowRef) {
        yield put(DepositActions.popupBlocked(DepositMessages().POPUP_BLOCKED));
        return;
    }

    while (!windowRef.closed) {
        yield delay(1000);
    }

    yield call(checkDepositStatusTrustly);
}

export function* abortTrustlyDeposit(): SagaIterator {
    try {
        yield call(checkDepositStatusTrustly);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}

import type {SagaIterator} from "redux-saga";
import {put, call, takeLatest} from "redux-saga/effects";
import * as HighlightsAPI from "./highlightsAPI";
import * as HighlightsActions from "./highlightsActions";
import {FETCH_HIGHLIGHTS} from "./highlightsActionTypes";

export function* getHighlights(): SagaIterator<void> {
    try {
        const response = yield call(HighlightsAPI.fetchHighlights);
        yield put(HighlightsActions.receiveHighlights(response.data));
    } catch (error: unknown) {
        yield put(HighlightsActions.fetchHighlightsError());
    }
}

export default function* highlightsSaga() {
    yield takeLatest(FETCH_HIGHLIGHTS, getHighlights);
}

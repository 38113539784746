export const VIEW_STATE_CONSTANTS = {
    UNREGISTRED: "UNREGISTRED",
    SUMMARY: "SUMMARY",
    EDIT: "EDIT",
    DELETE: "DELETE",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    ERROR_REQUEST_TIMEOUT: "ERROR_REQUEST_TIMEOUT",
    ERROR_EWS_TIMEOUT: "ERROR_EWS_TIMEOUT",
    ERROR_SERVICE_UNAVAILABLE: "ERROR_SERVICE_UNAVAILABLE",
    DENIED_WITHDRAWAL_LIMIT_REACHED: "DENIED_WITHDRAWAL_LIMIT_REACHED",
    IN_PROGRESS: "IN_PROGRESS",
    WAITING: "WAITING",
    ERROR_SWISH_WRONG_NUMBER: "ERROR_SWISH_WRONG_NUMBER",
} as const;

export const BANK_ACCOUNT_STATUS_CONSTANTS = {
    VALID: "VALID",
    PENDING_VALIDATION: "PENDING_VALIDATION",
    SENDING_FOR_APPROVAL: "SENDING_FOR_APPROVAL",
    SENT_FOR_APPROVAL: "SENT_FOR_APPROVAL",
    REJECTED: "REJECTED",
} as const;

export const PROVIDER_CONSTANTS = {
    SWISH: "SWISH",
    BANKACCOUNT: "BANKACCOUNT",
    TRUSTLY: "TRUSTLY",
} as const;

export const ACTION_CONSTANTS = {
    WITHDRAW_SWISH: "withdraw/WITHDRAW_SWISH",
    WITHDRAW_MONEY: "withdraw/WITHDRAW_MONEY",
    WITHDRAW_MONEY_SUCCESS: "withdraw/WITHDRAW_MONEY_SUCCESS",
    WITHDRAW_MONEY_FAILURE: "withdraw/WITHDRAW_MONEY_FAILURE",
    WITHDRAW_MONEY_BLOCKED: "withdraw/WITHDRAW_MONEY_BLOCKED",

    UPDATE_WITHDRAW_AMOUNT: "withdraw/UPDATE_WITHDRAW_AMOUNT",
    CLEAR_STATUS_MESSAGES: "withdraw/CLEAR_STATUS_MESSAGES",

    FETCH_BANK_ACCOUNT: "withdraw/FETCH_BANK_ACCOUNT",
    FETCH_BANK_ACCOUNT_SUCCESS: "withdraw/FETCH_BANK_ACCOUNT_SUCCESS",
    FETCH_BANK_ACCOUNT_FAILURE: "withdraw/FETCH_BANK_ACCOUNT_FAILURE",

    REGISTER_BANK_ACCOUNT: "withdraw/REGISTER_BANK_ACCOUNT",
    REGISTER_BANK_ACCOUNT_SUCCESS: "withdraw/REGISTER_BANK_ACCOUNT_SUCCESS",
    REGISTER_BANK_ACCOUNT_FAILURE: "withdraw/REGISTER_BANK_ACCOUNT_FAILURE",

    DELETE_BANK_ACCOUNT: "withdraw/DELETE_BANK_ACCOUNT",
    DELETE_BANK_ACCOUNT_SUCCESS: "withdraw/DELETE_BANK_ACCOUNT_SUCCESS",
    DELETE_BANK_ACCOUNT_FAILURE: "withdraw/DELETE_BANK_ACCOUNT_FAILURE",

    WITHDRAW_RESPONSE: "withdraw/WITHDRAW_RESPONSE",

    SET_STATUS_MESSAGE: "withdraw/SET_STATUS_MESSAGE",
    WITHDRAW_OPTIONS: "withdraw/WITHDRAW_OPTIONS",
    FETCH_WITHDRAW_OPTIONS: "withdraw/FETCH_WITHDRAW_OPTIONS",
    FETCH_WITHDRAW_OPTIONS_SUCCESS: "withdraw/FETCH_WITHDRAW_OPTIONS_SUCCESS",
    FETCH_WITHDRAW_OPTIONS_FAILURE: "withdraw/FETCH_WITHDRAW_OPTIONS_FAILURE",
} as const;

export const API_CONSTANTS = {
    COMPLETED: "COMPLETED", // When withdrawal is completed
    DENIED: "DENIED", // When user is denied to do withdrawal: Exception with State¨
    DENIED_WITHDRAWAL_LIMIT_REACHED: "DENIED_WITHDRAWAL_LIMIT_REACHED", // When withdrawal limit (3 per day) is reached
    ERROR: "ERROR", // When withdrawal is initialized but something went wrong
    ERROR_REQUEST_TIMEOUT: "ERROR_REQUEST_TIMEOUT", // When withdrawal is initialized but request timed out
    ERROR_EWS_TIMEOUT: "ERROR_EWS_TIMEOUT", // When withdrawal is initialized but EWS timed out at backend
    ERROR_SERVICE_UNAVAILABLE: "ERROR_SERVICE_UNAVAILABLE", // When withdrawal is initialized but swish is unavailble
    ERROR_SWISH_WRONG_NUMBER: "ERROR_SWISH_WRONG_NUMBER", // When withdrawal is initialized but the number do not belong to the customer.
    IN_PROGRESS: "IN_PROGRESS", // When withdrawal is initialized
    APPROVED: "APPROVED",
    INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS", // When customer hasn't got enough money to withdraw
    CREDITED: "CREDITED", // When a withdrawal with trustly has been credited by trustly for some reason
    CANCEL: "CANCEL", // When a withdrawal with trustly has been cancelled by user
    UNKNOWN: "UNKNOWN", // When a withdrawal is requested, but we don't know if swish or trustly accepted the request
    WALLET_ERROR: "WALLET_ERROR",
    WALLET_REVERSAL_ERROR: "WALLET_REVERSAL_ERROR",
    REVERSAL: "REVERSAL", // when transaction is reversed (return attempted withdrawal amount)
    INIT: "INIT",
    NOT_FOUND: "NOT_FOUND", // Withdrawal not found or AccountIndex in Withdrawal does not match user
    DAILY_LIMIT_ERROR: "DAILY_LIMIT_ERROR",
    INVALID_CLEARING_NUMBER: "INVALID_CLEARING_NUMBER",
    INVALID_ACCOUNT_NUMBER: "INVALID_ACCOUNT_NUMBER",
    BANK_ACCOUNT_REJECTED: "BANK_ACCOUNT_REJECTED",
    BANK_ACCOUNT_DENIED: "BANK_ACCOUNT_DENIED",
    ACCOUNT_NOT_ACTIVE: "ACCOUNT_NOT_ACTIVE",
} as const;

import {combineReducers} from "redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {
    type UserGamblingPeriodResult,
    constants,
} from "@atg-responsible-gambling-shared/user-gambling-result-types";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {FetchGamblingPeriodResultAction, Action} from "./actionTypes";

const {
    GAMBLING_PERIOD_RESULT_FETCH,
    GAMBLING_PERIOD_RESULT_SUCCESS,
    GAMBLING_PERIOD_RESULT_RESET,
} = constants;

const initialState: FetchState<UserGamblingPeriodResult> = {
    __loadingState: {error: false, loaded: false, loading: false},
};

const userGamblingPeriodResult: FetchReducer<
    UserGamblingPeriodResult,
    FetchGamblingPeriodResultAction
> = createFetchReducer(
    GAMBLING_PERIOD_RESULT_FETCH,
    GAMBLING_PERIOD_RESULT_SUCCESS,
    GAMBLING_PERIOD_RESULT_RESET,
    (state: FetchState<UserGamblingPeriodResult>, action: Action) => {
        switch (action.type) {
            case GAMBLING_PERIOD_RESULT_SUCCESS:
                if (action.error) return state;

                if (action.payload) {
                    return {
                        day: action.payload?.day,
                        week: action.payload?.week,
                        month: action.payload?.month,
                        year: action.payload?.year,
                    };
                }

                return state;

            case LOGOUT_FINISHED:
                return initialState;
            default:
                return state;
        }
    },
    initialState,
);

export const userGamblingResult = combineReducers({
    userGamblingPeriodResult,
});

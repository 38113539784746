import {combineReducers} from "redux";
// Fix when atg-api-mocks is split
// eslint-disable-next-line @nx/enforce-module-boundaries
import {WithdrawReducer, type WithdrawDomainTypes} from "@atg-payment/withdraw-domain";
import {DepositReducer, type DepositState} from "@atg-payment-shared/deposit-domain";

export type GlobalPaymentState = {
    payment: {
        deposit: DepositState;
        withdraw: WithdrawDomainTypes.WithdrawState;
    };
};

const paymentReducer = combineReducers({
    deposit: DepositReducer,
    withdraw: WithdrawReducer,
});

export default paymentReducer;

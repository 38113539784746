import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchCountryAction} from "./countryActions";
import {COUNTRY_REQUEST, COUNTRY_RECEIVE} from "./countryActions";

export interface Country {
    value: string | null;
}

const countryReducer: FetchReducer<Country, FetchCountryAction> = createFetchReducer(
    COUNTRY_REQUEST,
    COUNTRY_RECEIVE,
    "",
    (state: Country, action: FetchCountryAction) =>
        action.type === COUNTRY_RECEIVE && !action.error
            ? {value: action.payload?.value}
            : state,
    {value: null},
);

export default countryReducer;

import {clone, has} from "lodash";
import dayjs from "dayjs";
import root from "window-or-global";
import logger from "@atg-shared/log";
import * as GTMFormatters from "./GTMFormatters";

// This type contains all fields that are parsed somehow (in this file).
type Data = Readonly<{
    [key: string]: any;
    // eslint-disable-next-line camelcase
    purchase_event?: string;
    userDate?: string;
}>;

/**
 * @deprecated
 * This function is deprecated in favor of `trackEvent` and `trackVariable` from `atg-analytics`,
 * which have flow support and improved documentation.
 */
export function deprecated_logEvent(data: Data) {
    if (!data) return;

    // Clone data object to prevent Tealiums strange mutation on the object
    logToGoogleTagManager(clone({...data, userDate: dayjs().format("YYYY-MM-DD")}));
}

function formatEnhancedECommerceGTM(data: Data) {
    const {purchase_event: purchaseEvent} = data;

    // Switch needed because the different ecommerce objects sent to GTM look slightly different
    switch (purchaseEvent) {
        case GTMFormatters.PURCHASE:
            return GTMFormatters.formatPurchaseEvent(data);
        case GTMFormatters.CHECKOUT:
            return GTMFormatters.formatCheckoutEvent(data);
        case GTMFormatters.PRODUCT_CLICK:
            return GTMFormatters.formatProductClickEvent(data);
        case GTMFormatters.PRODUCT_DETAIL:
            return GTMFormatters.formatProductDetailEvent(data);
        case GTMFormatters.SUBSCRIPTION:
            return GTMFormatters.formatSubscriptionPurchaseEvent(data);
        case GTMFormatters.PRODUCT_ADD:
            return GTMFormatters.formatProductAddEvent(data);
        default:
            return data;
    }
}

function logToGoogleTagManager(data: Data) {
    // If the data has a purchase event we need to reformat the data for GTM
    const dataToSend = has(data, "purchase_event")
        ? formatEnhancedECommerceGTM(data)
        : GTMFormatters.formatNonPurchaseEvent(data);

    logger.debug("Analytics >> Google Tag Manager", dataToSend);

    // @ts-ignore the dataLayer type on root is not picked up when running tsc from packages/atgapp causing this line to fail...
    const {dataLayer} = root;
    if (dataLayer) {
        dataLayer.push(dataToSend);
    }
}

import * as storage from "@atg-shared/storage";
import * as GameTypes from "@atg-horse-shared/game-types";
import {DEPRECATED_SELECT_CHANNEL} from "../video/videoActionConstants";
import {
    SELECT_QUICK_PLAY_TRACK,
    SELECT_QUICK_PLAY_RACE,
    SELECT_QUICK_PLAY_GAME_TYPE,
    ACTIVATE_AUTOMATIC_TRACK_SWITCHER,
    SET_LIVE_BETS_VISITED,
    SET_LIVE_BETS_TOGGLED,
    SELECT_LIVE_TRACK,
} from "./quickplayActions";

import type {QuickplayAction} from "./quickplayActions";

export type QuickplayState = {
    selectedTrack: number | null | undefined;
    selectedRaceId: string | null | undefined;
    selectedGameType: string | null | undefined;
    automatic: boolean;
    liveBetsToggled: boolean | null | undefined;
    liveBetsVisited: boolean;
};

const initialState = {
    automatic: true,
    liveBetsVisited: Boolean(storage.getItem("liveBetsVisited")),
    selectedTrack: null,
    selectedRaceId: null,
    selectedGameType: null,
    liveBetsToggled: null,
};

const quickplay = (
    state: QuickplayState = initialState,
    action: QuickplayAction,
): QuickplayState => {
    switch (action.type) {
        case SELECT_QUICK_PLAY_TRACK:
            return {
                ...state,
                selectedGameType: GameTypes.vinnare,
                selectedTrack: action.payload,
                selectedRaceId: null,
            };
        case SELECT_QUICK_PLAY_RACE:
            return {
                ...state,
                selectedGameType: action.payload.gameType,
                selectedRaceId: action.payload.raceId,
            };
        case SELECT_QUICK_PLAY_GAME_TYPE:
            return {
                ...state,
                selectedGameType: action.payload,
            };
        case ACTIVATE_AUTOMATIC_TRACK_SWITCHER:
            return {
                ...state,
                automatic: action.payload,
            };
        case SET_LIVE_BETS_VISITED:
            return {
                ...state,
                liveBetsVisited: action.payload,
            };
        case SET_LIVE_BETS_TOGGLED: {
            return {
                ...state,
                liveBetsToggled: action.payload,
            };
        }
        case SELECT_LIVE_TRACK: {
            return {
                ...state,
                selectedGameType: action.payload.currentGameType || GameTypes.vinnare,
                selectedRaceId: action.payload.selectedRaceId,
                selectedTrack: action.payload.selectedTrack,
            };
        }
        // @ts-expect-error
        case DEPRECATED_SELECT_CHANNEL: {
            return {
                ...state,
                selectedTrack: null,
                selectedRaceId: null,
                automatic: true,
            };
        }
        default:
            return state;
    }
};

export default quickplay;

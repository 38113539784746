export const SET_DEPOSIT_BUDGET = "budget/SET_DEPOSIT_BUDGET";
export const SET_DEPOSIT_BUDGET_FAILURE = "budget/SET_DEPOSIT_BUDGET_FAILURE";
export const SET_DEPOSIT_BUDGET_SUCCESS = "budget/SET_DEPOSIT_BUDGET_SUCCESS";

export const CLEAR_DEPOSIT_BUDGET_STATUS_MESSAGES =
    "budget/CLEAR_DEPOSIT_BUDGET_STATUS_MESSAGES";

export const BUDGET_MIN_LIMIT_IN_ORE = 50 * 100;
export const BUDGET_MAX_LIMIT_IN_ORE = 9000000 * 100;

export const BUDGET_DAY_LIMIT = "dailyLimit";
export const BUDGET_WEEK_LIMIT = "weeklyLimit";
export const BUDGET_MONTH_LIMIT = "monthlyLimit";
export const BET_LIMIT_HORSE = "betLimitHorse";
export const BET_LIMIT_SPORT = "betLimitSport";
export const BET_LIMIT_CASINO = "betLimitCasino";

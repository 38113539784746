import atgRequest from "@atg-shared/fetch";
import * as Time from "@atg-shared/server-time";
import {
    HARRY_SERVICE_URL,
    LEGACY_PRODUCT_SERVICE_URL,
    SUBSCRIPTION_SERVICE_URL,
} from "@atg-shared/service-url";
import {type Coupon} from "@atg-horse-shared/coupon-types";

const HARRY_BAGS_URL = `${LEGACY_PRODUCT_SERVICE_URL}/harry/bags`;
const HARRY_SUBSCRIPTIONS_URL = `${SUBSCRIPTION_SERVICE_URL}/harry`;
const HARRY_SUBSCRIPTION_AVAILABILITY_URL = `${SUBSCRIPTION_SERVICE_URL}/is_available/`;

export const fetchHarryBags = () => atgRequest(HARRY_BAGS_URL);

export const fetchHarrySubscriptions = () => atgRequest(HARRY_SUBSCRIPTIONS_URL);

export const fetchSimpleHarry = () => atgRequest(HARRY_SERVICE_URL);

export const fetchHarryTomorrow = () => {
    const tomorrow = Time.serverTime().add(1, "days").format("YYYY-MM-DD");

    return atgRequest(`${HARRY_SERVICE_URL}?from=${tomorrow}`);
};

export const fetchHarryFromTo = ({from, to}: {from?: string; to?: string} = {}) => {
    let periodString = "";
    if (from || to) {
        periodString = "?";
        if (from) {
            periodString = `${periodString}from=${from}`;
            if (to) {
                periodString += "&";
            }
        }
        if (to) {
            periodString = `${periodString}to=${to}`;
        }
    }

    return atgRequest(HARRY_SERVICE_URL + periodString);
};

export const fetchHarrySubscriptionAvailability = (bet: Coupon) => {
    const {type} = bet.game;

    return atgRequest(`${HARRY_SUBSCRIPTION_AVAILABILITY_URL}${type.toUpperCase()}`);
};

export const EXPAND_VIDEO_FRAME = "EXPAND_VIDEO_FRAME";
export const TOGGLE_VIDEO_FRAME_EXPANDED = "TOGGLE_VIDEO_FRAME_EXPANDED";
export const TOGGLE_VIDEO_ONLY = "TOGGLE_VIDEO_ONLY";
export const DEPRECATED_SELECT_CHANNEL = "DEPRECATED_SELECT_CHANNEL";
export const LEGACY_VIDEO_STORE_UPDATED = "LEGACY_VIDEO_STORE_UPDATED";
export const SET_VIDEO_ONLY = "SET_VIDEO_ONLY";
export const SET_POPOUT_FRAME = "SET_POPOUT_FRAME";
export const LOADED_CHANNEL_CONFIG = "LOADED_CHANNEL_CONFIG";

export const LOAD_VIDEO_CHANNELS_START = "LOAD_VIDEO_CHANNELS_START";
export const START_LISTENING_TO_CHANNELS_PUSH = "START_LISTENING_TO_CHANNELS_PUSH";
export const STOP_LISTENING_TO_CHANNELS_PUSH = "STOP_LISTENING_TO_CHANNELS_PUSH";
export const RECEIVE_SELECT_CHANNEL = "RECEIVE_SELECT_CHANNEL";
export const RECEIVE_CHANNELS_PUSH = "RECEIVE_CHANNELS_PUSH";
export const SELECT_CHANNEL = "SELECT_CHANNEL";

export const SET_MEDIA_WARMUP_START = "SET_MEDIA_WARMUP_START";
export const SET_MEDIA_ARCHIVE_START = "SET_MEDIA_ARCHIVE_START";

export const MEDIA_WARMUP = "MEDIA_WARMUP";
export const MEDIA_WARMUP_WINDOW = "MEDIA_WARMUP_WINDOW";
export const MEDIA_ARCHIVE = "MEDIA_ARCHIVE";
export const MEDIA_ARCHIVE_START = "MEDIA_ARCHIVE_START";

export const MEDIA_ARCHIVE_WINDOW = "MEDIA_ARCHIVE_WINDOW";
export const OPEN_VIDEO_POPUP = "OPEN_VIDEO_POPUP";
export const CLOSE_VIDEO_POPUP = "CLOSE_VIDEO_POPUP";
export const CLOSE_VIDEO_POPUP_START = "CLOSE_VIDEO_POPUP_START";

export const RESET_BANNER_RIGHT_VIDEO = "RESET_BANNER_RIGHT_VIDEO";
export const LOAD_CHANNELS_EXTERNAL_WINDOW = "LOAD_CHANNELS_EXTERNAL_WINDOW";

export const V2_SET_LIVE_VIDEO_QUALITY = "V2_SET_LIVE_VIDEO_QUALITY";

export const ACTIVATE_LOW_LATENCY_VIDEO = "ACTIVATE_LOW_LATENCY_VIDEO";
export const DEACTIVATE_LOW_LATENCY_VIDEO = "DEACTIVATE_LOW_LATENCY_VIDEO";

import root from "window-or-global";
import {isiOS} from "./device";

function computeStyle(cssText: string) {
    const testElement = root.document.createElement("cssCompatTestElement");
    testElement.id = "cssCompatTestElement";
    testElement.style.cssText = `display: none;${cssText}`;
    root.document.body.appendChild(testElement);
    const computedStyle = root.getComputedStyle(
        // @ts-expect-error will not be null, this id is added in the third line above
        root.document.querySelector("#cssCompatTestElement"),
    );

    return {
        computedStyle,
        testElement,
    };
}

function cleanUp(testElement: HTMLElement) {
    root.document.body.removeChild(testElement);
}

function supportsSticky() {
    if (!root.document || !root.document.createElement) return false;

    const cssText = "position: sticky;";
    const matcher = "sticky";

    const computeResult = computeStyle(cssText);
    const result = computeResult.computedStyle.position.match(matcher) !== null;
    cleanUp(computeResult.testElement);

    return result;
}

function getBrowserScrollbarWidth() {
    return root.innerWidth - root.document.documentElement.clientWidth;
}

// Any Safari browser that is not on iOS,
// example: Safari on MacOS, Safari on Windows
function isSafari() {
    const ua = navigator.userAgent.toLowerCase();
    return !isiOS() && ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
}

// Safari on iOS only
function isiOSSafari() {
    const ua = navigator.userAgent.toLowerCase();
    return isiOS() && ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
}

function isSafari11orAbove() {
    const ua = navigator.userAgent.toLowerCase();
    return isSafari() && /version\/[1-9][1-9](\.[\d]+|\.[\d]+\.[\d]+) safari/.test(ua);
}

const getHostname = () => (root.location ? root.location.hostname : "");

const getOrigin = () =>
    `${root.location.protocol}//${root.location.hostname}${
        root.location.port ? `:${root.location.port}` : ""
    }`;

const browser = {
    sticky: supportsSticky(),
    isSafari,
    isiOSSafari,
    isSafari11orAbove,
    getBrowserScrollbarWidth,
    getHostname,
    getOrigin,
};

export default browser;

import type {AmountType} from "@atg-payment-shared/deposit-types";

type DepositStartEvent = {
    event_type: "deposit_start";
    amount_added: number;
    payment_option: string;
    duration: number;
    times_changed_amount: number;
    test?: string;
    variation?: number;
    selected_payment: string;
};

type DepositSuccessEvent = {
    event_type: "deposit_success";
    amount_added: number;
    payment_option: string;
    deposit_delayed: "yes" | "no";
    amount_type: AmountType;
    pre_selected_btn_nr: number | null;
    test?: string;
    variation?: number;
    selected_payment: string;
};

type DepositFailEvent = {
    event_type: "deposit_fail";
    payment_option: string;
    fail_reason: string;
};

type DepositType = "deposit" | "low-balance-deposit";

type DepositAbortEvent = {
    event_type: "deposit_abort";
    deposit_type: DepositType;
};

export const depositStart = (
    amountAdded: number,
    option: string,
    duration: number,
    timesChangedAmount: number,
    selectedPayment: string,
    testName?: string,
    testVariation?: number,
): DepositStartEvent => ({
    event_type: "deposit_start",
    amount_added: amountAdded,
    payment_option: option,
    duration,
    times_changed_amount: timesChangedAmount,
    test: testName,
    variation: testVariation,
    selected_payment: selectedPayment,
});

export const depositSuccess = (
    amountAdded: number,
    isDelayed: boolean,
    option: string,
    amountType: AmountType,
    preSelectedBtnNr: number | null,
    selectedPayment: string,
    testName?: string,
    testVariation?: number,
): DepositSuccessEvent => ({
    event_type: "deposit_success",
    amount_added: amountAdded,
    payment_option: option,
    deposit_delayed: isDelayed ? "yes" : "no",
    test: testName,
    variation: testVariation,
    amount_type: amountType,
    pre_selected_btn_nr: preSelectedBtnNr,
    selected_payment: selectedPayment,
});

export const depositFail = (option: string, reason: string): DepositFailEvent => ({
    event_type: "deposit_fail",
    payment_option: option,
    fail_reason: reason,
});

export const depositAbort = (depositType: DepositType): DepositAbortEvent => ({
    event_type: "deposit_abort",
    deposit_type: depositType,
});

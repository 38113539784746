import {find, filter, includes} from "lodash";

export type CmsBanner = any;
export type CmsTeaser = any;
export type CmsVrGame = any;

export type CmsArticle = {
    id: string;
    body: string;
    tags: Array<string>;
    title: string;
    slug: string;
    description?: string;
    metaDescription?: string;
    metaIndex?: string;
    metaTitle?: string;
    canonical?: string;
    gameTagIds?: Array<string>;
};

type NodeAttributes = {
    id: string;
    title: string;
    slug: string;
    published: string;
};

type BaseCmsPage<T> = {
    type: T;
} & NodeAttributes;

type _CmsPage = BaseCmsPage<"page"> & {
    link: string;
};

type _CmsPageGrid = BaseCmsPage<"grid"> & {
    link: string;
};

export type CmsPageLeaf = _CmsPage | _CmsPageGrid;

type _CmsPageNode = BaseCmsPage<"node"> & {
    toASingleWinner: [];
    items: Array<CmsPage>;
    banners: Array<CmsBanner>;
    teasers: Array<CmsTeaser>;
    vrGames: Array<CmsVrGame>;
    gameTagIds?: Array<string>;
};

type _CmsPageGridNode = BaseCmsPage<"grid-node"> & {
    toASingleWinner: [];
    items: Array<CmsPage>;
    banners: Array<CmsBanner>;
    teasers: Array<CmsTeaser>;
    vrGames: Array<CmsVrGame>;
    gameTagIds?: Array<string>;
};

export type CmsPageNode = _CmsPageNode | _CmsPageGridNode;

export type CmsPage = CmsPageNode | CmsPageLeaf;

export const findBySlug = (page: CmsPage | null, slug: string) => {
    if (!page || page.type === "page" || page.type === "grid") return null;
    return find(page.items, (item) => item.slug === slug) || null;
};
export const findById = (page: CmsPage | null, id: string) => {
    if (!page || page.type === "page" || page.type === "grid") return null;
    return find(page.items, (item) => item.id === id) || null;
};

export const getFirstItem = (page: CmsPage | null) => {
    if (!page || page.type === "page" || page.type === "grid") return null;
    return page.items.length ? page.items[0].slug : null;
};

export const getPageChildren = (page: CmsPage | null) => {
    if (!page || page.type === "page" || page.type === "grid") return null;
    return page.items;
};

export const getDateSlugFromUrl = (url: string) => {
    if (!url) return null;
    const splits = url.split("/");
    if (splits.length <= 2) return null;
    return splits[2];
};

export const PRODUCT_PAGE_CMS_PATH = "/site/atg.se/current/products/";

export const getArticlesForGame = (
    articles: Array<CmsArticle>,
    gameId: string | null,
) => {
    if (!gameId) return articles;
    return filter(articles, (item) => includes(item.gameTagIds, `game-${gameId}`));
};

export const convertToCampaignPage = (page: CmsPageNode) => ({
    ...page,
    items: filter<CmsPage>(page.items, ["type", "grid-node"]),
});

/* eslint-disable max-classes-per-file */
import type {ApolloError} from "@apollo/client";

export class SkippedRetryOnManualLogin extends Error {}

export class UserCancelledLogin extends Error {}

export class AccessTokenMissingError extends Error {}

/**
 * Guard to check if the error is of a special case, where we skipped retrying the request after a user was presented with a login modal
 */
export function isSkippedRetryOnManualLogin(
    error: ApolloError | Error,
): error is SkippedRetryOnManualLogin {
    return (
        "networkError" in error && error.networkError instanceof SkippedRetryOnManualLogin
    );
}

/**
 * Guard to check if the error is of a special case, where user actively cancelled login
 */
export function isUserCancelledLogin(
    error: ApolloError | Error,
): error is UserCancelledLogin {
    return "networkError" in error && error.networkError instanceof UserCancelledLogin;
}

/**
 * Guard to check if the error is of a special case, access token is missing
 */
export function isAccessTokenMissingError(
    error: ApolloError | Error,
): error is AccessTokenMissingError {
    return (
        "networkError" in error && error.networkError instanceof AccessTokenMissingError
    );
}

/* eslint-disable camelcase */
import {last, times, reduce, camelCase, includes, find, map, toNumber} from "lodash";

type Data = {
    purchase_event?: string;
    userDate?: string;
    [key: string]: Array<string> | string | undefined;
};

type Product = {
    price: unknown;
    quantity: unknown;
};

const formatKey = (key: string) => last(key.split("_")) as string;

export const extractProductData = (data: Data, key = "product_name"): Array<Product> => {
    const numberOfProducts = data[key]?.length ?? 0;
    const productArray: Product[] = [];

    times(numberOfProducts, (index) => {
        const product = reduce(
            data,
            (result, value, _key) => {
                // @ts-expect-error: suppressed error during Flow to TS migration
                result[formatKey(_key)] = data[_key]![index];
                return result;
            },
            {} as Product,
        );
        productArray.push(product);
    });

    return productArray;
};

export const PURCHASE = "purchase";
export const CHECKOUT = "checkout";
export const PRODUCT_CLICK = "product_click";
export const PRODUCT_DETAIL = "product_detail";
export const SUBSCRIPTION = "subscription";
export const PRODUCT_ADD = "product_add";

export const calculatePricePerProduct = (productArray: Array<Product>) =>
    map<Product, Product>(productArray, (product) => {
        const productPrice = toNumber(product.price);
        const productQuantity = toNumber(product.quantity);

        const resultingPrice = productPrice / productQuantity;
        return {
            ...product,
            price: resultingPrice.toString(),
        };
    });

export const calculateRevenue = (productArray: Array<Product>) =>
    reduce(
        productArray,
        (result, value) => {
            const price = toNumber(value.price);
            const totalPrice = result + price;
            return totalPrice;
        },
        0,
    ).toString();

export const formatPurchaseEvent = ({
    product_name = [],
    product_id = [],
    product_price = [],
    product_category = [],
    product_controlcode = [],
    product_daystorace = [],
    product_quantity = [],
    product_racedate = [],
    product_track = [],
    product_variant = [],
    purchase_id,
    product_num_shares = [],
    product_num_shares_left = [],
    product_share_cost = [],
    product_ownprice = [],
    ...rest
}: Data) => {
    const productArray = extractProductData({
        product_name,
        product_id,
        product_price,
        product_category,
        product_controlcode,
        dimension11: product_daystorace,
        product_quantity,
        dimension12: product_racedate,
        product_track,
        product_variant,
        product_num_shares,
        product_num_shares_left,
        product_share_cost,
        product_ownprice,
    });
    const productArrayWithCalculatedPrices = calculatePricePerProduct(productArray);
    const product = {
        event: "Purchase",
        ecommerce: {
            purchase: {
                actionField: {
                    id: purchase_id,
                    // The original array contains the total price for all products which is what we want to calulate the total.
                    revenue: calculateRevenue(productArray),
                },
                products: productArrayWithCalculatedPrices,
            },
        },
        ...rest,
    };
    return product;
};

export const formatCheckoutEvent = ({
    product_category = [],
    product_id = [],
    product_name = [],
    product_price = [],
    product_quantity = [],
    product_track = [],
    product_variant = [],
    ...rest
}: Data) => ({
    event: "Checkout",
    ecommerce: {
        checkout: {
            actionField: {
                step: 1,
            },
            products: extractProductData({
                product_name,
                product_id,
                product_category,
                product_price,
                product_quantity,
                product_track,
                product_variant,
            }),
        },
    },
    ...rest,
});

export const formatProductClickEvent = ({
    product_category = [],
    product_id = [],
    product_list = [],
    product_name = [],
    product_position = [],
    product_variant = [],
    ...rest
}: Data) => ({
    event: "ProductClick",
    ecommerce: {
        click: {
            actionField: {
                list: product_list[0],
            },
            products: extractProductData({
                product_category,
                product_id,
                product_name,
                product_position,
                product_variant,
            }),
        },
    },
    ...rest,
});

export const formatProductDetailEvent = ({
    product_category = [],
    product_id = [],
    product_name = [],
    product_track = [],
    product_variant = [],
    ...rest
}: Data) => ({
    event: "ProductDetail",
    ecommerce: {
        detail: {
            actionField: {
                list: product_category[0],
            },
            products: extractProductData({
                product_category,
                product_id,
                product_name,
                product_track,
                product_variant,
            }),
        },
    },
    ...rest,
});

export const formatSubscriptionPurchaseEvent = ({
    product_subscription_name = [],
    product_subscription_value = [],
    ...rest
}: Data) => ({
    event: "SubscriptionPurchase",
    ecommerce: {
        actionField: {
            id: product_subscription_name[0],
        },
        purchase: {
            products: extractProductData(
                {
                    product_subscription_name,
                    product_subscription_value,
                },
                "product_subscription_name",
            ),
        },
    },
    ...rest,
});

export const formatProductAddEvent = ({
    product_category = [],
    product_id = [],
    product_name = [],
    product_price = [],
    product_quantity = [],
    product_track = [],
    product_variant = [],
    ...rest
}: Data) => ({
    event: "ProductAdd",
    ecommerce: {
        add: {
            products: extractProductData({
                product_category,
                product_id,
                product_name,
                product_price,
                product_quantity,
                product_track,
                product_variant,
            }),
        },
    },
    ...rest,
});

export function formatNonPurchaseEvent(data: {[key: string]: string}) {
    let eventName;
    const nonConformEvents = ["couponViewMore", "couponViewLess"];
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(prop) && prop === "event") {
            eventName = camelCase(data[prop]);
            break;
        } else if (
            // eslint-disable-next-line no-prototype-builtins
            data.hasOwnProperty(prop) &&
            typeof prop === "string" &&
            includes(prop.toLowerCase(), "event")
        ) {
            eventName = `${camelCase(prop).replace("Event", "")}_${data[prop]}`;
            break;
        } else if (
            // eslint-disable-next-line no-prototype-builtins
            data.hasOwnProperty(prop) &&
            find(nonConformEvents, (el) => el === prop)
        ) {
            eventName = camelCase(`${prop}_${data[prop]}`);
        }
    }
    return {
        event: eventName,
        ...data,
    };
}

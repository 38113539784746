import queryString from "query-string";
import {now} from "lodash";
import root from "window-or-global";
import {fetchAuthorized} from "@atg-shared/auth";
import {browser} from "@atg/utils";
import {DepositMethods, DepositURLs} from "@atg-payment-shared/deposit-utils";
import type {DepositOption, DepositOptionsResponse} from "../domainTypes";

type SwishDebugResponse = {
    [key: string]: string;
};

type DepositMoneyProps = {
    amount: number;
    option: DepositOption;
    noCvc?: boolean;
    ssnValidation?: boolean;
    openInNewWindow: boolean;
    mobilePhone?: string;
    debugSwishResponse?: SwishDebugResponse;
    phoneNumber?: string;
};

const makeCleanPaymentUrl = (url: string) => url.split("?")[0];

const selectedPaymentMethod = (option: DepositOption) => {
    let endpoint = "";

    switch (option.id) {
        case DepositMethods.swish: {
            endpoint = DepositURLs.SWISH_DIRECT_URL;
            break;
        }
        default: {
            // new and existing card
            endpoint = DepositURLs.CREDIT_CARD_URL;
        }
    }
    // remove unwanted query params from payment url
    const currentUrl = makeCleanPaymentUrl(root.location.href);
    const completeUrl = `${currentUrl}?&status=complete&method=${option.id}&orderId={orderId}`;
    const cancelUrl = `${currentUrl}?&status=cancelled&method=${option.id}&orderId={orderId}`;

    return {endpoint, completeUrl, cancelUrl};
};

export const depositMoneyIframe = ({
    amount,
    option,
    noCvc,
    ssnValidation,
    phoneNumber,
}: DepositMoneyProps) => {
    const {endpoint, completeUrl, cancelUrl} = selectedPaymentMethod(option);

    let storeCard;
    if (option.id === DepositMethods.newCard) {
        storeCard = option.storeCard;
    }

    let {id} = option;
    // We in FE use the id as "swish" but BE wants "swish-e-commerce"
    if (id === DepositMethods.swish) id = "swish-e-commerce";

    return fetchAuthorized(endpoint, {
        method: "POST",
        // @ts-expect-error
        dataType: "json",
        contentType: "application/json",
        headers: {
            token: now().toString(),
        },
        body: JSON.stringify({
            completeUrl,
            cancelUrl,
            amount,
            option: {
                ...option,
                id,
                phoneNumber,
            },
            noCvc,
            ssnValidation,
            storeCard,
        }),
    });
};

type Params = {
    orderRef?: string;
    newWindow?: boolean;
};

const newWindowParams = {newWindow: true};

const getUrl = (url: string, params?: Params) =>
    url + (params ? `?${queryString.stringify(params)}` : "");

// Function is used only for deposit flow via Trusly
export const depositMoneyTrustly = ({
    amount,
    option,
    openInNewWindow,
}: DepositMoneyProps) => {
    const isApp = window.requestedBy === "ATG-Live-App";
    const origin = browser.getOrigin();
    const currentUrl = origin + window.location.pathname;
    const currentParams = isApp ? undefined : queryString.parse(window.location.search);
    const newWindowUrl = `${origin}/konto/betalning`;

    const completeUrl = openInNewWindow
        ? getUrl(newWindowUrl, newWindowParams)
        : getUrl(currentUrl, currentParams);

    const cancelUrl = openInNewWindow
        ? getUrl(newWindowUrl, newWindowParams)
        : getUrl(currentUrl, currentParams);

    return fetchAuthorized(DepositURLs.TRUSTLY_URL, {
        method: "POST",
        // @ts-expect-error
        dataType: "json",
        contentType: "application/json",
        headers: {
            token: now().toString(),
        },
        body: JSON.stringify({
            completeUrl,
            cancelUrl,
            amount,
            option,
        }),
    });
};

export const deleteStoredBankCard = () =>
    fetchAuthorized(`${DepositURLs.OPTIONS_URL}/creditcard`, {
        method: "DELETE",
    });

export const updateOrderRef = (orderRef: string) =>
    fetchAuthorized(`${DepositURLs.DEPOSIT_URL}/${orderRef}`, {
        method: "PUT",
        // @ts-expect-error
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({}),
    });

export const checkStatusSwishDirect = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.SWISH_DIRECT_URL}/${orderId}`);

export const checkStatusTrustly = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.TRUSTLY_URL}/${orderId}`);

export const deletePaymentTrustly = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.TRUSTLY_URL}/${orderId}`, {
        method: "DELETE",
    });

export const checkStatusIframe = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.CREDIT_CARD_URL}/${orderId}`);

export const cancelInitiatedDepositInIframe = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.CREDIT_CARD_URL}/${orderId}`, {
        method: "DELETE",
    });

export const cancelSwishDirect = (orderId: string) =>
    fetchAuthorized(`${DepositURLs.SWISH_DIRECT_URL}/${orderId}`, {
        method: "DELETE",
    });

export const fetchOptionsIframe = () =>
    fetchAuthorized<DepositOptionsResponse>(DepositURLs.OPTIONS_URL);

import {persistReducer} from "redux-persist";
import {combineReducers} from "redux";
import root from "window-or-global";
import CookieStorage from "@atg-shared/storage/cookieStorage";
import AsyncStorageAdapter from "@atg-shared/storage/asyncStorageAdapter";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {
    AnswerQuestionnaire,
    AnswerState,
    KycQuestionnaire,
    KycQuestionnaireState,
} from "@atg-aml-shared/kyc-types";
import {DisplayAreas, ModalRequiredStatus} from "@atg-aml-shared/kyc-types";
import type * as KycQuestionnaireActions from "../actions/kycQuestionnaireActions";
import * as KycQuestionnaireActionTypes from "../actions/kycQuestionnaireActionsTypes";

const initialState: KycQuestionnaireState = {
    canSeeKycQuestionnaire: false,
    modalRequired: ModalRequiredStatus.NONE,
    displayAreas: DisplayAreas.LOGIN,
    questionnaire: {} as KycQuestionnaire,
    lastDateBeforeBlock: "",
};

const questionnaire = (
    state: KycQuestionnaireState = initialState,
    action: KycQuestionnaireActions.Action,
): KycQuestionnaireState => {
    switch (action.type) {
        case KycQuestionnaireActionTypes.FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE:
            if (action.error) return state;
            return {
                ...state,
                canSeeKycQuestionnaire: action.payload,
            };
        case KycQuestionnaireActionTypes.FETCH_MODAL_REQUIRED_RECEIVE:
            if (action.error) return state;
            return {
                ...state,
                // If modal is required, we always want canSeeKyc to be true
                canSeeKycQuestionnaire:
                    action.payload.modalRequired !== "NONE" ||
                    state.canSeeKycQuestionnaire,
                modalRequired: action.payload.modalRequired,
                lastDateBeforeBlock: action.payload.lastDateBeforeBlock,
            };
        case KycQuestionnaireActionTypes.FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE:
            if (action.error) return state;
            return {
                ...state,
                questionnaire: action.payload.questionnaire,
            };
        case KycQuestionnaireActionTypes.UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN:
            return {
                ...state,
                usersLastTimeModalViewed: {
                    ...state.usersLastTimeModalViewed,
                    ...action.payload,
                },
            };
        case KycQuestionnaireActionTypes.RESET_KYC_STATE:
            return initialState;
        default:
            return state;
    }
};

const answerStatus = createFetchReducer(
    KycQuestionnaireActionTypes.KYC_QUESTIONNAIRE_ANSWERS_REQUEST,
    KycQuestionnaireActionTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE,
    KycQuestionnaireActionTypes.KYC_QUESTIONNAIRE_ANSWERS_RESET,
    (
        state: AnswerState,
        action:
            | KycQuestionnaireActions.SetKycQuestionnaireQuestionsAction
            | KycQuestionnaireActions.ResetKycStateAction,
    ) => {
        switch (action.type) {
            case KycQuestionnaireActionTypes.KYC_QUESTIONNAIRE_ANSWERS_RECEIVE:
                if (action.error) return state;
                return action.payload;
            case KycQuestionnaireActionTypes.RESET_KYC_STATE:
                return {result: ""};
            default:
                return state;
        }
    },
    {
        result: "",
    },
);

const questionnaireAnswer = createFetchReducer(
    KycQuestionnaireActionTypes.FETCH_KYC_ACTUAL_ANSWERS_REQUEST,
    KycQuestionnaireActionTypes.FETCH_KYC_ACTUAL_ANSWERS_RECEIVE,
    "",
    (
        state: AnswerQuestionnaire,
        action:
            | KycQuestionnaireActions.FetchQuestionnaireMostRecentAction
            | KycQuestionnaireActions.UpdateKycQuestionnaireQuestionAction
            | KycQuestionnaireActions.ResetKycStateAction,
    ) => {
        const {type} = action;
        if (
            type === KycQuestionnaireActionTypes.FETCH_KYC_ACTUAL_ANSWERS_RECEIVE &&
            !action.error
        ) {
            return {answers: action.payload?.answers};
        }
        if (type === KycQuestionnaireActionTypes.UPDATE_KYC_QUESTIONNAIRE_QUESTION) {
            return {answers: action.payload?.answers};
        }
        if (type === KycQuestionnaireActionTypes.RESET_KYC_STATE) {
            return {answers: []};
        }
        return state;
    },
    {answers: []},
);

// the cookie should be shared between atg.se and tillsammans.atg.se, therefore we remove tillsammans from the domain name (using atg.se means that it is accessible in atg.se including all its subdomains, i.e. tillsammans.atg.se)
// we also want to share between www.atg.se and atg.se domains, so also removing that from the host name
const cookieDomain = root.location.host.replace("www.", "").replace("tillsammans.", "");

const kycQuestionnairePersistConfig = {
    // Using CookieStorage instead of LocalStorage means that the persisted state can be shared in both atg.se and tillsammans.atg.se.
    // redux-persist storage must follow the AsyncStorage API, therefore wrapping it in AsyncStorageAdapter
    // see ref: https://github.com/rt2zz/redux-persist/blob/master/docs/api.md#type-persistconfig
    storage: new AsyncStorageAdapter(new CookieStorage("", cookieDomain)),
    key: "kyc",
    whitelist: ["usersLastTimeModalViewed"],
    version: 0,
};

const kycQuestionnaire = combineReducers<any, any>({
    questionnaire: persistReducer(kycQuestionnairePersistConfig, questionnaire),
    answerStatus,
    questionnaireAnswer,
});

export default kycQuestionnaire;

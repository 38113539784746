import type {FetchState} from "@atg-shared/fetch-types";

/**
 * ModalRequiredStatus enum of KYC statuses, possible value: NONE, REMINDER, BLOCK
 * NONE - we don't showing KYC modal, but customer can see form in "Mina pengar" page.
 * REMINDER - we showing KYC modal after login (once per 24h).
 * BLOCK - we showing KYC modal after login (once per 24h) and before customer try to make bet all time.
 */
export enum ModalRequiredStatus {
    NONE = "NONE",
    REMINDER = "REMINDER",
    BLOCK = "BLOCK",
}

/**
 * DisplayAreas enum used for analytic, possible value: LogIn, MY_ACCOUNT, PLACE_BET
 */
export enum DisplayAreas {
    LOGIN = "LogIn",
    MY_ACCOUNT = "MyAccount",
    PLACE_BET = "PlaceBet",
}

export type KycAnswerOption = {
    answerId: number;
    key: string;
};

export type KycQuestion = {
    questionId: Question;
    key: string;
    maxSelectableAnswers: number;
    answerOptions: Array<KycAnswerOption>;
};

export type Question = {
    value: number;
};

export type Answer = {
    answerId: number;
    answerKey?: string | ConcatArray<string>;
};

type KycAnswer = {
    selectedAnswers: Array<Answer>;
};

export type KycAnswerRequest = {
    questionId: number;
} & KycAnswer;

export type KycAnswerResponse = {
    questionKey: string;
    questionId: Question;
} & KycAnswer;

export type KycQuestionnaire = {
    questions: Array<KycQuestion>;
};

type KycQuestionnaireAnswers = {
    answers: Array<KycAnswerRequest>;
};

export type KycQuestionnaireAnswersRequest = {
    questionnaire: KycQuestionnaireAnswers;
};

type LastTimeModalViewed = {
    lastTimeModalViewed: string;
};

export type UsersLastTimeModalViewed = {
    [key: string]: LastTimeModalViewed;
};

export type KycQuestionnaireState = {
    canSeeKycQuestionnaire: boolean;
    modalRequired: ModalRequiredStatus;
    lastDateBeforeBlock: string;
    displayAreas: DisplayAreas;
    questionnaire: KycQuestionnaire;
    usersLastTimeModalViewed?: UsersLastTimeModalViewed;
};

export type AnswerState = {
    result?: string;
};

export type AnswerQuestionnaire = {
    answers: Array<KycAnswerResponse>;
    questionnaireId?: number;
};

export type ModalRequiredResponse = {
    lastDateBeforeBlock: string;
    modalRequired: ModalRequiredStatus;
};

export type CombineState = {
    questionnaire: KycQuestionnaireState;
    answerStatus: FetchState<AnswerState>;
    questionnaireAnswer: FetchState<AnswerQuestionnaire>;
};

export type GlobalState = {
    kycQuestionnaire: CombineState;
};

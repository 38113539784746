export enum SpeedPayoutActionType {
    REQUEST_BET_SPEED_PAYOUT = "REQUEST_BET_SPEED_PAYOUT",
    RECEIVE_BET_SPEED_PAYOUT = "RECEIVE_BET_SPEED_PAYOUT",
    ERROR_SPEED_PAYOUT = "ERROR_SPEED_PAYOUT",
}

export type SpeedPayoutActionPayload = {
    amount: number;
    fee: number;
    timestamp: string;
};

export type requestBetSpeedPayoutAction = {
    type: SpeedPayoutActionType.REQUEST_BET_SPEED_PAYOUT;
    payload: {
        betId: string;
        placedAt: string;
    };
};

export type receiveBetSpeedPayoutAction = {
    type: SpeedPayoutActionType.RECEIVE_BET_SPEED_PAYOUT;
    payload: SpeedPayoutActionPayload;
};

export type errorBetSpeedPayoutAction = {
    type: SpeedPayoutActionType.ERROR_SPEED_PAYOUT;
    payload: number;
};

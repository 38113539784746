import type {
    errorBetSpeedPayoutAction,
    receiveBetSpeedPayoutAction,
    requestBetSpeedPayoutAction,
    SpeedPayoutActionPayload,
} from "./speedPayoutActions";
import {SpeedPayoutActionType} from "./speedPayoutActions";

export type BetSpeedPayoutState = {
    isLoading: boolean;
    hasError: boolean;
    responseCode: number | null;
    data: SpeedPayoutActionPayload | null;
};

const INITIAL_STATE: BetSpeedPayoutState = {
    isLoading: false,
    hasError: false,
    responseCode: null,
    data: null,
};

const betSpeedPayoutReducer = (
    state = INITIAL_STATE,
    action:
        | requestBetSpeedPayoutAction
        | receiveBetSpeedPayoutAction
        | errorBetSpeedPayoutAction,
): BetSpeedPayoutState => {
    switch (action.type) {
        case SpeedPayoutActionType.REQUEST_BET_SPEED_PAYOUT:
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        case SpeedPayoutActionType.RECEIVE_BET_SPEED_PAYOUT:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                data: action.payload,
            };
        case SpeedPayoutActionType.ERROR_SPEED_PAYOUT:
            return {
                ...state,
                isLoading: false,
                hasError: true,
                responseCode: action.payload,
            };
        default:
            return state;
    }
};

export default betSpeedPayoutReducer;

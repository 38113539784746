import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as UserActions from "@atg-global-shared/user/userActions";
import {DepositStatuses} from "@atg-payment-shared/deposit-utils";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    errorMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
} from "../helpers/sagaHelpers";

export function* initDepositIframe(payload: Record<string, any>): SagaIterator {
    try {
        yield put(DepositActions.clearStatusMessages());
        yield put(DepositActions.clearIframeDepositState());
        // @ts-expect-error
        return yield call(DepositApi.depositMoneyIframe, payload);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
        return null;
    }
}

export function* handleIFrameFlow(payload: Record<string, any>): SagaIterator {
    const response = yield call(initDepositIframe, payload);
    if (!response) return;

    const {redirectUrl, hostedViewUrl, amount, orderId} = response.data;
    yield put(
        DepositActions.setIframeDepositState(redirectUrl, hostedViewUrl, amount, orderId),
    );
}

export function* finalizeDepositIframe(): SagaIterator {
    try {
        const orderId = yield select(DepositSelectors.orderIdForDepositIframe);
        const maxPollTime = 3000;
        let timeSpent = 0;
        let response = yield call(DepositApi.checkStatusIframe, orderId);

        let {amount, status} = response.data;

        while (status === DepositStatuses.IN_PROGRESS && timeSpent < maxPollTime) {
            yield delay(POLL_TIMEOUT_TICK);
            timeSpent += POLL_TIMEOUT_TICK;

            try {
                response = yield call(DepositApi.checkStatusIframe, orderId);
                ({amount, status} = response.data);
            } catch (error: unknown) {
                const err = error as DepositResponseError;
                yield call(errorMessage, err.response);
            }
        }

        /**
         * payex is not always reliable and may return status "finished" too early,
         * even if the deposit has not been completed in our BE.
         * We poll the status to our BE until it is finished for real.
         *
         * If we exceed the max time, we cancel the deposit and emulate a
         * timeout failure. (Since we don't get a real timeout from BE)
         *
         * Note that this finalize function runs AFTER the payex iframe is
         * finished which means that this function runs on the initial deposit
         * screen. This may cause confusion both for us and our users.
         */
        if (status === DepositStatuses.IN_PROGRESS && timeSpent >= maxPollTime) {
            yield call(statusMessage, DepositStatuses.DELAYED, amount);
        } else {
            yield put(UserActions.fetchBalance());
            yield call(statusMessage, status, amount);
        }

        yield put(DepositActions.clearIframeDepositState());
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}

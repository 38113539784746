import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";

import fetchCountry from "./fetchCountry";
import type {Country} from "./countryReducer";

export const COUNTRY_REQUEST = "country/request";
export const COUNTRY_RECEIVE = "country/receive";

export type FetchCountryAction = FetchAction<
    typeof COUNTRY_REQUEST,
    typeof COUNTRY_RECEIVE,
    Country
>;

export const fetchCountryAction = (): FetchCountryAction => ({
    type: FETCH,
    payload: {
        requestAction: COUNTRY_REQUEST,
        receiveAction: COUNTRY_RECEIVE,
        callApi: call(fetchCountry),
    },
});

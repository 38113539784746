import {KYC_SERVICE_URL} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import type {
    AnswerQuestionnaire,
    AnswerState,
    KycQuestionnaireAnswersRequest,
    ModalRequiredResponse,
} from "@atg-aml-shared/kyc-types";

const KYC_CAN_SEE_QUESTIONNAIRE_URL = `${KYC_SERVICE_URL}/can-see-questionnaire`;
const KYC_MODAL_REQUIRED_URL = `${KYC_SERVICE_URL}/modal-required`;
const KYC_MOST_RECENT_URL = `${KYC_SERVICE_URL}/most-recent`;
export const KYC_QUESTIONNAIRE_URL = `${KYC_SERVICE_URL}/`;

export const fetchKycCanSeeQuestionnaire = () =>
    fetchAuthorized<boolean>(KYC_CAN_SEE_QUESTIONNAIRE_URL);

export const fetchKycModalRequired = () =>
    fetchAuthorized<ModalRequiredResponse>(KYC_MODAL_REQUIRED_URL);

export const fetchKycQuestionnaire = () => fetchAuthorized(KYC_QUESTIONNAIRE_URL);

export const fetchKycMostRecentAnswer = () =>
    fetchAuthorized<AnswerQuestionnaire>(KYC_MOST_RECENT_URL);

export const seyKycQuestionnaireAnswers = (
    kycQuestionnaireAnswers: KycQuestionnaireAnswersRequest,
) =>
    fetchAuthorized<AnswerState>(KYC_QUESTIONNAIRE_URL, {
        method: "POST",
        body: JSON.stringify(kycQuestionnaireAnswers),
    });

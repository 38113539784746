/**
 * Dispatched when we received from KYC(know your customer) backend service that a customer is enable to see KYC form,
 * it's mean customer marked as medium or high risk level.
 * This action specific for displaying KYC form in "Mina pengar" page.
 */
export const CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT =
    "CAN_SEE_KYC_QUESTIONNAIRE_IN_ACCOUNT";

/**
 * Dispatched when we start KYC(know your customer) flow before sent first request on backend service,
 */
export const START_KYC_QUESTIONNAIRE_FLOW = "START_KYC_QUESTIONNAIRE_FLOW";

/**
 * Dispatched when we received from KYC(know your customer) backend service that a customer is enable to see KYC form,
 * it's mean customer marked as medium or high risk level.
 * This action specific for displaying KYC modal before customer make bet.
 */
export const CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET =
    "CAN_SEE_KYC_QUESTIONNAIRE_BEFORE_BET";

/**
 * Dispatched when customer see KYC(know your customer) modal after login,
 * used for showing modal window after login only once per 24h
 */
export const UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN =
    "UPDATED_TIME_WHEN_CUSTOMER_SAW_KYC_AFTER_LOGIN";

/**
 * Dispatched when sending customer KYC(know your customer) answer
 */
export const KYC_QUESTIONNAIRE_ANSWERS_REQUEST = "KYC_QUESTIONNAIRE_ANSWERS_REQUEST";

/**
 * Dispatched when have receive from KYC(know your customer) after sending customer answer
 */
export const KYC_QUESTIONNAIRE_ANSWERS_RECEIVE = "KYC_QUESTIONNAIRE_ANSWERS_RECEIVE";

/**
 * Dispatched when needed reset KYC(know your customer) last answer request
 */
export const KYC_QUESTIONNAIRE_ANSWERS_RESET = "KYC_QUESTIONNAIRE_ANSWERS_RESET";

/**
 * Dispatched when needed fetch KYC(know your customer) questionnaire
 */
export const FETCH_KYC_QUESTIONNAIRE_QUESTION_REQUEST =
    "FETCH_KYC_QUESTIONNAIRE_QUESTION_REQUEST";

export const FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE =
    "FETCH_KYC_QUESTIONNAIRE_QUESTION_RECEIVE";

export const FETCH_KYC_QUESTIONNAIRE_QUESTION_RESET =
    "FETCH_KYC_QUESTIONNAIRE_QUESTION_RESET";

/**
 * Dispatched when needed update KYC(know your customer) answer after customer filled form
 */
export const UPDATE_KYC_QUESTIONNAIRE_QUESTION = "UPDATE_KYC_QUESTIONNAIRE_QUESTION";

/**
 * Dispatched when KYC(know your customer) form from modal filled and customer closed final modal
 */
export const FINISH_KYC_QUESTIONNAIRE_FLOW = "FINISH_KYC_QUESTIONNAIRE_FLOW";

/**
 * Dispatched when KYC(know your customer) form from modal closed by customer and customer dont filled questionnaire
 */
export const CLOSE_KYC_QUESTIONNAIRE_FLOW = "CLOSE_KYC_QUESTIONNAIRE_FLOW";

/**
 * Dispatched when needed fetch actual customer answer from KYC(know your customer)
 */
export const FETCH_KYC_ACTUAL_ANSWERS_REQUEST = "FETCH_KYC_ACTUAL_ANSWERS_REQUEST";

/**
 * Dispatched when have receive from KYC(know your customer) after fetch actual customer answer
 */
export const FETCH_KYC_ACTUAL_ANSWERS_RECEIVE = "FETCH_KYC_ACTUAL_ANSWERS_RECEIVE";

/**
 * Dispatched when we want to reset the local KYC state completely
 */
export const RESET_KYC_STATE = "RESET_KYC_STATE";

export const FETCH_CAN_SEE_QUESTIONNAIRE_REQUEST = "FETCH_CAN_SEE_QUESTIONNAIRE_REQUEST";
export const FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE = "FETCH_CAN_SEE_QUESTIONNAIRE_RECEIVE";

export const FETCH_MODAL_REQUIRED_REQUEST = "FETCH_MODAL_REQUIRED_REQUEST";
export const FETCH_MODAL_REQUIRED_RECEIVE = "FETCH_MODAL_REQUIRED_RECEIVE";

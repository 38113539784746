/**
 * A simple TTL (time-to-live) cache that automatically removes entries after a set amount of time.
 *
 * **Note:**
 * This utility class is specifically written to be compatible with lodash's
 * [memoize](https://lodash.com/docs/4.17.15#memoize) function.
 *
 * @example
 * const myCache = new TTLCache<string, number>(5000);
 *
 * myCache.set("/some-url", 42);
 * myCache.get("/some-url"); // 42
 *
 * // wait 5 seconds
 *
 * myCache.get("/some-url") // undefined
 */
export default class TTLCache<K, V> extends Map<K, V> {
    timeout: number;

    /**
     * @param timeout time (in milliseconds) before cache entry expires
     */
    constructor(timeout: number) {
        super();
        this.timeout = timeout;
    }

    set(key: K, value: V): this {
        super.set(key, value);
        setTimeout(() => this.delete(key), this.timeout);
        return this;
    }
}

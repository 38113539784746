import {
    REQUEST_CMS_MISC,
    RECEIVE_CMS_MISC,
    REQUEST_CMS_PAGE,
    RECEIVE_CMS_PAGE,
    REQUEST_CMS_CAMPAIGN_PAGE,
    RECEIVE_CMS_CAMPAIGN_PAGE,
    REQUEST_ARTICLE,
    RECEIVE_ARTICLE,
    REQUEST_ARTICLE_BY_LINK,
    RESET_ARTICLE,
} from "./cmsConstants";

/*
 *  Page action creators
 */

export const fetchCmsPage = (path) => ({
    type: REQUEST_CMS_PAGE,
    payload: {
        path,
    },
});

export const receiveCmsPage = (path, page) => ({
    type: RECEIVE_CMS_PAGE,
    error: false,
    payload: {
        path,
        page,
    },
});

export const fetchCmsCampaignPage = (path) => ({
    type: REQUEST_CMS_CAMPAIGN_PAGE,
    payload: {
        path,
    },
});

export const receiveCmsCampaignPage = (path, page) => ({
    type: RECEIVE_CMS_CAMPAIGN_PAGE,
    error: false,
    payload: {
        path,
        page,
    },
});

export const receiveCmsCampaignPageError = (path, message) => ({
    type: RECEIVE_CMS_CAMPAIGN_PAGE,
    error: true,
    payload: {
        path,
        message,
    },
});

export const receiveCmsPageError = (path, message) => ({
    type: RECEIVE_CMS_PAGE,
    error: true,
    payload: {
        path,
        message,
    },
});

/*
 *  Article action creators
 */
export const resetCmsArticle = () => ({
    type: RESET_ARTICLE,
});

export const fetchCmsArticle = (id) => ({
    type: REQUEST_ARTICLE,
    payload: {id},
});

export const fetchCmsArticleByLink = (id, link) => ({
    type: REQUEST_ARTICLE_BY_LINK,
    payload: {id, link},
});

export const receiveCmsArticle = (id, article) => ({
    type: RECEIVE_ARTICLE,
    error: false,
    payload: {
        id,
        article,
    },
});

export const receiveCmsArticleError = (id, message) => ({
    type: RECEIVE_ARTICLE,
    error: true,
    payload: {
        id,
        message,
    },
});

export const loadCMSMisc = () => ({
    type: REQUEST_CMS_MISC,
    payload: {},
});

export const receiveCMSMisc = (response) => ({
    type: RECEIVE_CMS_MISC,
    payload: response,
});

export const receiveCMSMiscError = (error) => ({
    type: RECEIVE_CMS_MISC,
    error: true,
    payload: error,
});

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import * as HorseFavoritesActions from "./horseFavoritesActions";

export type HorseFavorites = {
    horses: Array<HorseFavoritesActions.FavoriteHorse> | null;
    preferences?: HorseFavoritesActions.Preferences;
    showHorseFavoritesSettingsModal: boolean;
    isLoading: boolean;
    hasError: boolean;
};

const INITIAL_STATE: HorseFavorites = {
    horses: null,
    showHorseFavoritesSettingsModal: false,
    isLoading: false,
    hasError: false,
};

const horseFavorites = (
    state: HorseFavorites = INITIAL_STATE,
    action: HorseFavoritesActions.Types,
): HorseFavorites => {
    switch (action.type) {
        case HorseFavoritesActions.STORE_FAVORITE_HORSE:
            return {
                ...state,
                horses: state.horses ? [...state.horses, action.horse] : [action.horse],
            };

        case HorseFavoritesActions.STORE_ALL_FAVORITE_HORSES:
            return {
                ...state,
                horses: action.horses,
            };

        case HorseFavoritesActions.STORE_FAVORITES_PREFERENCES:
            return {
                ...state,
                preferences: action.preferences,
            };

        case HorseFavoritesActions.REMOVE_FAVORITE_HORSE_FROM_STORE:
            return {
                ...state,
                horses: state.horses
                    ? state.horses.filter((horse) => horse.id !== action.horseId)
                    : null,
            };

        case HorseFavoritesActions.CLOSE_HORSE_FAVORITES_SETTINGS_MODAL:
            return {
                ...state,
                showHorseFavoritesSettingsModal: false,
            };

        case HorseFavoritesActions.SHOW_HORSE_FAVORITES_SETTINGS_MODAL:
            return {
                ...state,
                showHorseFavoritesSettingsModal: true,
            };

        case HorseFavoritesActions.LOADING_STARTED:
            return {
                ...state,
                isLoading: true,
            };

        case HorseFavoritesActions.LOADING_FINISHED:
            return {
                ...state,
                isLoading: false,
            };

        case HorseFavoritesActions.SET_ERROR:
            return {
                ...state,
                hasError: true,
            };

        case HorseFavoritesActions.CLEAR_ERROR:
            return {
                ...state,
                hasError: false,
            };

        case LOGOUT_FINISHED:
            return {
                ...state,
                horses: null,
                preferences: undefined,
                showHorseFavoritesSettingsModal: false,
                isLoading: false,
            };

        default:
            return state;
    }
};

const horseFavoritesPersistConfig = {
    storage,
    key: "horseFavorites",
    version: 0,
};

export default persistReducer(horseFavoritesPersistConfig, horseFavorites);

import type {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import log from "@atg-shared/log";
import * as Storage from "@atg-shared/storage";
import {MuiAlertTypes} from "@atg-global-shared/alerts-types";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {
    DepositMessages,
    GENERIC_TECHNICAL_ERROR_CODES,
    getDepositErrorCodeMessage,
    DepositStatuses,
    DepositStorageKeys,
    DepositMethods,
} from "@atg-payment-shared/deposit-utils";
import {type PaymentMessage} from "@atg-payment-shared/payment-status-message-types";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import type {DepositOption, DepositStatusTypes} from "../../domainTypes";
import * as DepositApi from "../../api/api";

/**
 * Saga Helpers
 */

export const POLL_TIMEOUT_TICK = 1000;

type DepositError = {
    meta: {
        statusCode: number;
    };
    data: {status: string; code?: number; message?: string; creditCardRetries?: number};
};

export type DepositResponseError = {
    response: DepositError;
};

export function getStoredUserDepositPreference(
    userName: string,
): DepositOption | undefined {
    const storedUserDepositPreferences = Storage.getObject(
        DepositStorageKeys.userDepositPreferences,
    );
    if (storedUserDepositPreferences) {
        if (storedUserDepositPreferences) {
            const preference = storedUserDepositPreferences[userName] as DepositOption;
            // If people have saved deposit preferences as 'swish-e-commerce', we need to change it to 'swish'
            if (preference?.id === "swish-e-commerce")
                preference.id = DepositMethods.swish;

            return preference;
        }
    }
    return undefined;
}

export function* storeUserDepositPreferences(
    selectedOption: DepositOption,
): SagaIterator {
    const userName = yield select(UserSelectors.getUsername);

    yield call(Storage.setObject, DepositStorageKeys.userDepositPreferences, {
        [userName]: selectedOption,
    });
}

export function* deleteStoredUserDepositPreference(userName: string): SagaIterator {
    const storedUserDepositPreferences = Storage.getObject(
        DepositStorageKeys.userDepositPreferences,
    );
    if (storedUserDepositPreferences) {
        // The local storage can store multiple users preferences, so only delete the current user
        delete storedUserDepositPreferences[userName];
        // Need to remove the current local storge and then save it again with the updated state
        Storage.removeItem(DepositStorageKeys.userDepositPreferences);
        yield call(
            Storage.setObject,
            DepositStorageKeys.userDepositPreferences,
            storedUserDepositPreferences,
        );
    }
}

export function* statusMessage(status: DepositStatusTypes, amount: number): SagaIterator {
    switch (status) {
        case DepositStatuses.COMPLETED:
        case DepositStatuses.DELAYED: {
            let delayed = false;
            let message = DepositMessages().PAYIN_SUCCESS;

            if (status === DepositStatuses.DELAYED) {
                message = DepositMessages().PAYIN_DELAYED;
                delayed = true;
            }

            // mandatory to fetch a user or user's balance
            const action = yield call(UserActions.fetchBalance);
            if (action && action.type === UserActionTypes.RECEIVE_BALANCE_ERROR) {
                message = DepositMessages().PAYIN_DELAYED;
            }

            yield put(DepositActions.depositSuccess(amount, message, delayed));

            const depositOption: DepositOption = yield select(
                DepositSelectors.selectedOption,
            );

            // If the user selects a new card when deposit, the next time default method should be existing card instead of new card
            if (depositOption.id === DepositMethods.newCard)
                depositOption.id = DepositMethods.existingCard;
            // BE sends response id as 'swish-e-commerce' bu we want it to only be 'swish'
            if (depositOption.id === "swish-e-commerce")
                depositOption.id = DepositMethods.swish;

            yield call(storeUserDepositPreferences, depositOption);

            return;
        }
        case DepositStatuses.BUDGET_EXCEEDED:
            yield put(
                DepositActions.depositFailure(
                    DepositMessages().PAYIN_RETURN_MONEY_BUDGET_EXCEEDED,
                ),
            );
            return;
        case DepositStatuses.ERROR:
            yield put(DepositActions.depositFailure(DepositMessages().TECHNICAL_ERROR));
            return;
        case DepositStatuses.CANCELLED:
            yield put(
                DepositActions.depositFailure(DepositMessages().TRANSACTION_CANCELLED),
            );
            return;
        case DepositStatuses.DECLINED:
            yield put(DepositActions.depositPending(DepositMessages().PAYIN_DECLINED));
            return;
        default:
            yield put(DepositActions.depositPending(DepositMessages().PAYIN_IN_PROGRESS));
    }
}

function* errorMessageErrorCodes(
    text: string,
    title?: string,
    showCustomerServicePhoneNumber?: boolean,
): SagaIterator {
    const msgObject: PaymentMessage = {
        id: "deposit-error-message",
        text,
        type: MuiAlertTypes.ERROR,
        title,
        showCustomerServicePhoneNumber: showCustomerServicePhoneNumber ?? false,
    };
    return yield put(DepositActions.depositFailure(msgObject));
}

function* errorMessages({
    meta: {statusCode},
    data: {status},
}: DepositError): SagaIterator {
    switch (statusCode) {
        case 0:
            return yield put(
                DepositActions.depositFailure(DepositMessages().CONNECTION_LOST),
            );
        case 403:
            return yield put(
                DepositActions.depositFailure(DepositMessages().LOGIN_REQUIRED),
            );
        case 406:
            return yield put(
                DepositActions.depositFailure(DepositMessages().PAYIN_DENIED),
            );
        case 409:
            switch (status) {
                /**
                 * The DENIED status is not provided by the payment service BE but may be passed along from
                 * Payex when something goes wrong inside the payex flow (which is out of our control).
                 */
                case DepositStatuses.PAYEX_DENIED:
                    return yield put(
                        DepositActions.depositFailure(
                            DepositMessages().TRANSACTION_CANCELLED,
                        ),
                    );
                default:
                    return yield put(
                        DepositActions.depositFailure(
                            DepositMessages().PAYIN_IN_PROGRESS,
                        ),
                    );
            }
        case 429:
            return yield put(
                DepositActions.depositFailure(
                    DepositMessages().PAYIN_DECLINED_TOO_MANY_REQUESTS,
                ),
            );
        case 452:
            if (status === DepositStatuses.NEED_TO_SET_BUDGET) {
                return yield put(
                    DepositActions.depositFailure(DepositMessages().NEED_TO_SET_BUDGET),
                );
            }
            return yield put(
                DepositActions.depositFailure(
                    DepositMessages().PAYIN_AMOUNT_EXCEEDS_BUDGET,
                ),
            );

        default:
            log.error(`Inserting money to atg account failed with status ${statusCode}`);
            return yield put(
                DepositActions.depositFailure(DepositMessages().TECHNICAL_ERROR),
            );
    }
}

export function errorMessage({meta, data}: DepositError): SagaIterator {
    const status = data && data.status ? data.status : "";
    const code = data && data.code ? data.code : "";
    const codeAsString = code.toString();
    const cardRetriesLeft = data?.creditCardRetries;

    if (!code) return errorMessages({meta, data: {status}});

    const errorCodeIsTechnicalError =
        GENERIC_TECHNICAL_ERROR_CODES.includes(codeAsString);

    if (errorCodeIsTechnicalError) {
        const {title, text, showCustomerServicePhoneNumber} =
            DepositMessages().GENERIC_TECHNICAL_ERROR_MESSAGE;
        return errorMessageErrorCodes(text, title, showCustomerServicePhoneNumber);
    }

    const message = getDepositErrorCodeMessage(code, cardRetriesLeft);

    if (message) {
        const {title, text, showCustomerServicePhoneNumber} = message;
        return errorMessageErrorCodes(text, title, showCustomerServicePhoneNumber);
    }
    return errorMessages({meta, data: {status}});
}

export function* initDeposit(payload: Record<string, any>): SagaIterator {
    try {
        return yield call(
            DepositApi.depositMoneyTrustly,
            payload as {amount: number; option: DepositOption; openInNewWindow: boolean},
        );
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err?.response);
        return null;
    }
}

import features, {paymentMicroFe} from "@atg-shared/client-features";

const paymentMicroFeIsActive = features.isEnabled(paymentMicroFe);

export const ACCOUNT_SETTINGS_PAGE = paymentMicroFeIsActive
    ? "/account-settings"
    : "konto/installningar";

export const TRANSACTIONS_PAGE = paymentMicroFeIsActive
    ? "/payment/transactions"
    : "/konto/overforingar/transaktioner";

export const DEPOSIT_PAGE = paymentMicroFeIsActive
    ? "/payment/deposit"
    : "/konto/overforingar/insattning";

export const WITHDRAW_PAGE = paymentMicroFeIsActive
    ? "/payment/withdraw"
    : "/konto/overforingar/uttag";

export const MONEY_PAGE = paymentMicroFeIsActive ? "/payment" : "/konto/overforingar";

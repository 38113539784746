import _ from "lodash";

const MAX_SAFE_INTEGER = 2 ** 53 - 1;

const isLength = (value: number) =>
    typeof value === "number" &&
    value > -1 &&
    value % 1 === 0 &&
    value <= MAX_SAFE_INTEGER;

function removeUndefinedFromSecondArg(secondArg: any) {
    if (_.isPlainObject(secondArg)) {
        return _.reduce(
            secondArg,
            (result, value, key) => {
                if (!_.isUndefined(value)) result[key] = value;
                return result;
            },
            {} as Record<string, any>,
        );
    }
    return secondArg;
}

function mergePlain(...rest: any) {
    const lastArg = _.last(rest);
    let args = _.toArray(rest);
    let customizer: any;

    if (_.isFunction(lastArg)) {
        customizer = lastArg;
        args = _.initial(args);
    } else {
        customizer = _.noop;
    }

    function onlyMergePlainObjects(value: any, srcValue: any) {
        if (!srcValue) return undefined;

        const plainObjectOrArray =
            (isLength(srcValue.length) &&
                (_.isArray(srcValue) || _.isTypedArray(srcValue))) ||
            _.isPlainObject(srcValue) ||
            _.isArguments(srcValue);

        if (!plainObjectOrArray) return srcValue;
        // eslint-disable-next-line prefer-spread
        return customizer.apply(undefined, rest);
    }

    args = args.concat(onlyMergePlainObjects);
    args[1] = removeUndefinedFromSecondArg(args[1]);

    // @ts-expect-error
    return _.mergeWith(...args);
}

export default mergePlain;

import {
    call,
    put,
    takeLatest,
    select,
    take,
    type ActionPattern,
} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {constants} from "@atg-responsible-gambling-shared/budget-types";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import * as Storage from "@atg-shared/storage";
import {RouterSelectors} from "@atg-shared/router";
import {DepositMethods, DepositStorageKeys} from "@atg-payment-shared/deposit-utils";
import {MemberActions} from "@atg-global-shared/member-data-access";
import {LimitsSelectors} from "@atg-responsible-gambling-shared/limits-domain";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import {showDepositModal, showDepositLimitModal} from "atg-modals/modalActions";
import {MODAL_CLOSE} from "atg-modals/modalActionTypes";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as DepositSelectors from "../selectors/selectors";
import * as DepositActions from "../actions/actions";
import type * as Action from "../actions/actionTypes";

export function* takeSuccessful(actions: ActionPattern): SagaIterator<void> {
    let action;
    do {
        action = yield take(actions);
    } while (action.error);
    return action;
}

export function* handleDepositFlow(
    depositAction: Action.StartDepositFlowAction,
): SagaIterator {
    yield put(AuthActions.checkAuth());
    const action = yield call(takeSuccessful, [
        AuthActions.AUTH_CHECK_RESPONSE,
        MemberActions.CANCELLED_LOGIN_FLOW,
    ]);

    if (action.type === AuthActions.AUTH_CHECK_RESPONSE) {
        const mustSetDepositLimit = yield select(
            LimitsSelectors.shouldForceDepositBudgetUpdate,
        );

        if (mustSetDepositLimit) {
            yield put(showDepositLimitModal());

            const limitAction = yield call(takeSuccessful, [
                constants.SET_DEPOSIT_BUDGET_SUCCESS,
                MODAL_CLOSE,
            ]);

            if (limitAction.type === MODAL_CLOSE) return;
        }

        const depositModalPayload = depositAction?.payload?.header;
        yield put(DepositActions.setGameInfo(depositModalPayload));
        yield put(showDepositModal());
    }
}

export function* clearDepositState(): SagaIterator {
    yield call(Storage.removeItem, DepositStorageKeys.isInDepositFlow);
    yield call(Storage.removeItem, DepositStorageKeys.depositModalHeader);
    yield call(Storage.removeItem, DepositStorageKeys.isDepositFlowOnPage);
    yield call(Storage.removeItem, DepositStorageKeys.depositSelectedOption);
}

export function* saveDepositState(action: Action.DepositMoneyAction): SagaIterator {
    const isDepositFlowInModal = yield select(ModalSelectors.isInDepositFlow);
    const isDepositFlowOnPage = yield select(RouterSelectors.isOnDepositPage);
    const isDepositFlowOnTillsammansPage = yield select(
        RouterSelectors.isOnTillsammansDepositPage,
    );

    // Since depositFlow within a purchase modal i.e. horse bet has it's own
    // logic for this, we do not need to save the deposit state here on redirects,
    // otherwise, we will get double modals on returning
    const shouldSaveDepositState =
        isDepositFlowInModal || isDepositFlowOnPage || isDepositFlowOnTillsammansPage;
    if (!shouldSaveDepositState) return;
    const {option} = action && action.payload;

    if (
        (isDepositFlowOnTillsammansPage || isDepositFlowInModal) &&
        option.id === DepositMethods.trustly
    ) {
        yield call(Storage.setItem, DepositStorageKeys.depositWithTrustlyStarted, "true");
    }

    // We save deposit state only for bank-tranfers because we leave a page
    // credit cards and swish works in iframe so we do not need a save state for them
    const willRedirect = option.id === DepositMethods.trustly;

    if (willRedirect) {
        const header = yield select(ModalSelectors.getDepositModalHeader);
        // Get branding, so on failed bank transfer, we can show it when we redirect
        yield call(
            Storage.setItem,
            DepositStorageKeys.depositModalHeader,
            JSON.stringify(header),
        );
        yield call(Storage.setItem, DepositStorageKeys.isInDepositFlow, "true");
        if (isDepositFlowOnPage) {
            // Set's an extra flag for deposit page redirects, since we don't rely on query param anymore
            // using `isInDepositFlow` leads to race conditions between sometimes, better safe than sorry
            yield call(Storage.setItem, DepositStorageKeys.isDepositFlowOnPage, "true");
        }
        const selectedOption = yield select(DepositSelectors.selectedOption);
        yield call(
            Storage.putObject,
            DepositStorageKeys.depositSelectedOption,
            selectedOption,
        );
    }
}

export function* getDepositModalHeaderFromStorage(): SagaIterator {
    try {
        return yield call(Storage.getObject, DepositStorageKeys.depositModalHeader);
    } catch {
        return undefined;
    }
}

export function* loadDepositState(): SagaIterator {
    const savedDepositState = yield call(
        Storage.getItem,
        DepositStorageKeys.isInDepositFlow,
    );
    if (savedDepositState == null) return;

    // If we return from bank transfer we want to show the branding again
    const header = yield call(getDepositModalHeaderFromStorage);

    const selectedOption = yield call(
        Storage.getObject,
        DepositStorageKeys.depositSelectedOption,
    );
    yield put(DepositActions.setSelectedOption(selectedOption));

    // show deposit modal only if user is not on deposit page
    const isRegularDepositPage = yield select(RouterSelectors.isOnDepositPage);
    const isTillsammansDepositage = yield select(
        RouterSelectors.isOnTillsammansDepositPage,
    );
    const isDepositPage = isRegularDepositPage || isTillsammansDepositage;

    if (!isDepositPage) {
        yield put(showDepositModal(header));
    }

    yield put(DepositActions.depositFinalize());
    yield call(clearDepositState);
}

export function* handleDepositState(
    action:
        | Action.DepositMoneyAction
        | Action.DepositMoneyFailureAction
        | Action.LoadDepositState,
): SagaIterator {
    switch (action.type) {
        case DepositActionConstants.DEPOSIT_MONEY:
            yield call(saveDepositState, action);
            break;
        case DepositActionConstants.LOAD_DEPOSIT_STATE:
            yield call(loadDepositState);
            break;
        case DepositActionConstants.DEPOSIT_FAILURE:
            yield call(clearDepositState);
            break;
        default:
            // triggered by BaseFrame on every page reload
            yield call(loadDepositState);
            break;
    }
}

export default function* depositFlowSaga(): SagaIterator {
    yield takeLatest(
        DepositActionConstants.START_DEPOSIT_FLOW_IN_MODAL,
        handleDepositFlow,
    );
    yield takeLatest(
        [
            DepositActionConstants.DEPOSIT_MONEY,
            DepositActionConstants.DEPOSIT_FAILURE,
            DepositActionConstants.LOAD_DEPOSIT_STATE,
            DepositActionConstants.DEPOSIT_SUCCESS,
        ],
        handleDepositState,
    );
}

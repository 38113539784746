export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_INIT = "CHANGE_PASSWORD_INIT";
export const CHANGE_PASSWORD_RESPONSE = "CHANGE_PASSWORD_RESPONSE";
export const CHANGE_PASSWORD_CLEAR = "CHANGE_PASSWORD_CLEAR";

export type ChangePasswordParams = {
    oldPassword: string;
    newPassword: string;
};

export type ChangePasswordInitAction = {
    type: typeof CHANGE_PASSWORD_INIT;
};
export const changePasswordInit = (): ChangePasswordInitAction => ({
    type: CHANGE_PASSWORD_INIT,
});

export type ChangePasswordAction = {
    type: typeof CHANGE_PASSWORD;
    params: ChangePasswordParams;
};
export const changePassword = (params: ChangePasswordParams): ChangePasswordAction => ({
    type: CHANGE_PASSWORD,
    params,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChangePasswordPayload = Record<string, any>;

export type ChangePasswordSuccessAction = {
    type: typeof CHANGE_PASSWORD_RESPONSE;
    payload: ChangePasswordPayload;
};

export type ChangePasswordErrorAction = {
    type: typeof CHANGE_PASSWORD_RESPONSE;
    payload: ChangePasswordPayload;
    error: true;
};

export const changePasswordSuccess = (
    payload: ChangePasswordPayload,
): ChangePasswordSuccessAction => ({
    type: CHANGE_PASSWORD_RESPONSE,
    payload,
});

export const changePasswordError = (
    payload: ChangePasswordPayload,
): ChangePasswordErrorAction => ({
    type: CHANGE_PASSWORD_RESPONSE,
    error: true,
    payload,
});

export type ChangePasswordClearAction = {
    type: typeof CHANGE_PASSWORD_CLEAR;
};

export const changePasswordClear = (): ChangePasswordClearAction => ({
    type: CHANGE_PASSWORD_CLEAR,
});

export type ChangePasswordActions =
    | ChangePasswordInitAction
    | ChangePasswordSuccessAction
    | ChangePasswordErrorAction
    | ChangePasswordClearAction
    | ChangePasswordAction;

import {call, put, select, take, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import {DepositStatuses} from "@atg-payment-shared/deposit-utils";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
} from "../helpers/sagaHelpers";
import {initDepositIframe} from "../iframe/iframeSagas";
import type {DepositStatusTypes} from "../../domainTypes";

type OrderData = {
    amount: number;
    orderId: string;
};

export function* checkSwishDirectDepositStatus(
    {amount, orderId}: OrderData,
    pollTimeout: number,
): SagaIterator {
    let timeSpent = 0;
    const firstCheck = 10_000; // 10 sec in ms
    let nextCheck = 1;
    let status: DepositStatusTypes = DepositStatuses.IN_PROGRESS;

    while (status === DepositStatuses.IN_PROGRESS && timeSpent <= pollTimeout) {
        try {
            if (timeSpent === firstCheck || timeSpent === nextCheck) {
                const response = yield call(DepositApi.checkStatusSwishDirect, orderId);
                status = response.data.status;
                nextCheck = timeSpent + 3000;
            }
        } finally {
            timeSpent += POLL_TIMEOUT_TICK;
            yield delay(POLL_TIMEOUT_TICK);
        }
    }

    // need to fetch user to get updated depositBudget and playBudget
    yield put(UserActions.fetchBalance());
    yield take([UserActionTypes.RECEIVE_BALANCE, UserActionTypes.RECEIVE_BALANCE_ERROR]);
    yield call(statusMessage, status, amount);
    yield put(DepositActions.resetDepositIspending());
    yield put(DepositActions.setSwishDirectStep(DepositStatuses.SWISH_DIRECT_RESET));
}

export function* cancelSwishDirect(): SagaIterator {
    try {
        const {swishDirectOrderId} = yield select(DepositSelectors.orderIdSwishDirect);
        if (swishDirectOrderId) {
            yield call(DepositApi.cancelSwishDirect, swishDirectOrderId);
        }
        yield put(DepositActions.clearIframeDepositState());
        yield put(DepositActions.resetDepositIspending());
    } catch (e: unknown) {
        const err = e as DepositResponseError;
        log.error("cancelSwishDirectError: ", {error: serializeError(err)});
    }
}

export function* handleSwishDirectFlow(payload: Record<string, any>): SagaIterator {
    const response = yield call(initDepositIframe, {
        ...payload,
        isSwishDirect: true,
    });

    if (!response) return;
    const {amount, orderId} = response.data;
    const pollTimeout = 180 * 1000; // 3 minutes
    yield put(DepositActions.setSwishDirectId(orderId));
    yield call(checkSwishDirectDepositStatus, {amount, orderId}, pollTimeout);
}

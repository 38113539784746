import type {
    errorFileBetAction,
    LegacyFileBet,
    receiveFileBetAction,
    requestFileBetAction,
} from "./fileBetActions";
import {FileBetActionType} from "./fileBetActions";

export type FileBetState = {
    isLoading: boolean;
    hasError: boolean;
    data: Array<LegacyFileBet>;
};

const INITIAL_STATE: FileBetState = {
    isLoading: false,
    hasError: false,
    data: [],
};

const fileBetReducer = (
    state = INITIAL_STATE,
    action: receiveFileBetAction | errorFileBetAction | requestFileBetAction,
): FileBetState => {
    switch (action.type) {
        case FileBetActionType.REQUEST_FILE_BETS:
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        case FileBetActionType.RECEIVED_FILE_BETS:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                data: action.payload,
            };
        case FileBetActionType.ERROR_FILE_BETS:
            return {
                ...state,
                isLoading: false,
                hasError: action.payload,
            };
        default:
            return state;
    }
};

export default fileBetReducer;

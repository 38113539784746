import {type HarryFlavor} from "@atg-horse-shared/bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponActions} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {ReductionTerms} from "@atg-horse/reduced-bets";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PLACE_BET} from "@atg-horse/horse-bet";
import {broadcastAction} from "atg-store-addons";
import type {Product} from "./products";

/**
 * Dispatched when a new purchase is initiated (purchase modal is opened)
 */
export const START_PURCHASE_FLOW = "purchase/startPurchaseFlow";

/**
 * Dispatched when a purchase flow ends, successfully or not (purchase modal is closed)
 */
export const FINISH_PURCHASE_FLOW = "purchase/finishPurchaseFlow";

export const SHOW_PURCHASE_RECEIPT = "purchase/showPurchaseReceipt";
export const CONFIRM_BET = "purchase/confirmBet";
export const LOAD_PURCHASE_STATE = "purchase/loadPurchaseState";
export const RESTORE_PURCHASE_FLOW = "purchase/restorePurchaseFlow";
export const RESTORE_PRODUCT = "purchase/restoreProduct";
export const HARRY_SUBSCRIPTION_PURCHASED = "purchase/harrySubscriptionPurchased";
export const SHOW_LOW_BALANCE_MESSAGE = "purchase/showLowBalanceMessage";
export const RESET_PURCHASE_IN_PROCESS = "purchase/resetPurchaseInProcess";
export const CHANGE_HARRY_FLAVOUR = "purchase/changeHarryFlavour";
export const DEPOSIT_LIMITS_AFTER_CONFIRMATION =
    "purchase/depositLimitsAfterConfirmation";

type RestoreCouponOptions = {
    coupon: any;
    cid: string;
};

type RestoreVRCouponOptions = {
    coupon: any;
    gameId: string;
};

type RestorePurchaseFlowAction = {
    type: typeof RESTORE_PURCHASE_FLOW;
    payload: any;
};

type RestoreProductAction = {
    type: typeof RESTORE_PRODUCT;
    payload: any;
};

type LoadPurchaseStateAction = {
    type: typeof LOAD_PURCHASE_STATE;
};

export type PurchaseAction = {
    type: typeof START_PURCHASE_FLOW;
    payload: {
        product: Product;
        context: any;
    };
};

export type ConfirmBetAction = {
    type: typeof CONFIRM_BET;
    payload: {
        coupon: any;
        liveView?: boolean;
    };
};

type ShowPurchaseReceiptAction = {
    type: typeof SHOW_PURCHASE_RECEIPT;
    payload: any;
};

type ChangeHarryFlavourAction = {
    type: typeof CHANGE_HARRY_FLAVOUR;
    payload: {
        harryFlavour?: HarryFlavor;
    };
};

export type FinishPurchaseAction = {
    type: typeof FINISH_PURCHASE_FLOW;
};

type RestoreCouponAction = {
    type: typeof CouponActions.RESTORE_COUPON;
    payload: RestoreCouponOptions;
};

type RestoreVRCouponAction = {
    type: typeof CouponActions.RESTORE_VR_COUPON;
    payload: RestoreVRCouponOptions;
};

type PurchaseHarryBagAction = {
    type: typeof PLACE_BET;
    payload: {
        bet: any;
        token: string;
    };
};

type HarrySubscriptionPurchasedAction = {
    type: typeof HARRY_SUBSCRIPTION_PURCHASED;
    payload: {
        subscriptionResponse: {
            type: "harry-subscription";
        };
    };
};

type HarrySubscriptionPurchasedErrorAction = {
    type: typeof HARRY_SUBSCRIPTION_PURCHASED;
    error: true;
    payload: {
        status: string;
    };
};

type PurchaseHarrySubscriptionAction = {
    type: typeof PLACE_BET;
    payload: {
        bet: any;
        token: string;
    };
};

type ShowLowBalanceMessageAction = {
    type: typeof SHOW_LOW_BALANCE_MESSAGE;
    payload: {
        betCost: number;
    };
};

type ResetPurchaseInProcessAction = {
    type: typeof RESET_PURCHASE_IN_PROCESS;
};

type DepositLimitsAfterConfirmationAction = {
    type: typeof DEPOSIT_LIMITS_AFTER_CONFIRMATION;
};

export const depositLimitsAfterConfirmation =
    (): DepositLimitsAfterConfirmationAction => ({
        type: DEPOSIT_LIMITS_AFTER_CONFIRMATION,
    });

export const restorePurchaseFlow = (payload: any): RestorePurchaseFlowAction => ({
    type: RESTORE_PURCHASE_FLOW,
    payload,
});

export const restoreProduct = (purchaseState: any): RestoreProductAction => ({
    type: RESTORE_PRODUCT,
    payload: purchaseState,
});

export const loadPurchaseState = (): LoadPurchaseStateAction => ({
    type: LOAD_PURCHASE_STATE,
});

/**
 * Start a new purchase flow.
 *
 * This will open a purchase modal and guide the user through a few screens. The flow always ends
 * with the `finishPurchase()` action.
 */
export const purchase = (
    product: Product,
    context: Record<string, any> = {},
): PurchaseAction =>
    // this action can be triggered from start page (global app), therefore broadcasting action
    broadcastAction({
        type: START_PURCHASE_FLOW,
        payload: {
            product,
            context,
        },
    });

export const changeHarryFlavour = (
    harryFlavour?: HarryFlavor,
): ChangeHarryFlavourAction => ({
    type: CHANGE_HARRY_FLAVOUR,
    payload: {
        harryFlavour,
    },
});

/**
 * Go to the "confirm" screen in the purchase flow modal
 *
 * Dispatched in `purchaseFlowSaga` – do not dispatch this elsewhere.
 */
export const confirmBet = (coupon?: any, liveView = false): ConfirmBetAction => ({
    type: CONFIRM_BET,
    payload: {
        coupon,
        liveView,
    },
});

/**
 * Go to the "receipt" screen in the purchase flow modal
 *
 * Dispatched in `purchaseFlowSaga` – do not dispatch this elsewhere.
 */
export const showPurchaseReceipt = (options: any): ShowPurchaseReceiptAction => ({
    type: SHOW_PURCHASE_RECEIPT,
    payload: options,
});

/**
 * Indicate that a purchase flow has finished, either because it was completed successfully, or
 * because the user cancelled it.
 */
export const finishPurchase = (): FinishPurchaseAction =>
    broadcastAction({
        type: FINISH_PURCHASE_FLOW,
    });

export const restoreCoupon = ({
    coupon,
    cid,
}: RestoreCouponOptions): RestoreCouponAction => ({
    type: CouponActions.RESTORE_COUPON,
    payload: {
        coupon,
        cid,
    },
});

export const restoreVRCoupon = ({
    coupon,
    gameId,
}: RestoreVRCouponOptions): RestoreVRCouponAction => ({
    type: CouponActions.RESTORE_VR_COUPON,
    payload: {
        coupon,
        gameId,
    },
});

export const placeBet = (bet: any, token: string, reductionTerms?: ReductionTerms) => ({
    type: PLACE_BET,
    payload: {
        bet,
        token,
        reductionTerms,
    },
});

export const purchaseHarryBag = (bag: any, token: string): PurchaseHarryBagAction => ({
    type: PLACE_BET,
    payload: {
        bet: bag,
        token,
    },
});

export const harrySubscriptionPurchased = (
    response: any,
): HarrySubscriptionPurchasedAction => ({
    type: HARRY_SUBSCRIPTION_PURCHASED,
    payload: {
        subscriptionResponse: {
            ...response,
            type: "harry-subscription",
        },
    },
});

export const harrySubscriptionPurchasedError = (
    status: string,
): HarrySubscriptionPurchasedErrorAction => ({
    type: HARRY_SUBSCRIPTION_PURCHASED,
    error: true,
    payload: {status},
});

export const purchaseHarrySubscription = (
    subscription: any,
    token: string,
): PurchaseHarrySubscriptionAction => ({
    type: PLACE_BET,
    payload: {
        bet: subscription,
        token,
    },
});

export const showLowBalanceMessage = (betCost: number): ShowLowBalanceMessageAction => ({
    type: SHOW_LOW_BALANCE_MESSAGE,
    payload: {betCost},
});

// nuke this, somehow...
export const resetPurchaseInProcess = (): ResetPurchaseInProcessAction => ({
    type: RESET_PURCHASE_IN_PROCESS,
});

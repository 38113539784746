// Opens a modal
export const MODAL_FLOW_TYPE = "MODAL_FLOW_TYPE";
// Opens a page
export const PAGE_FLOW_TYPE = "PAGE_FLOW_TYPE";
// To be used for authentication inline in other flows, for example purchase flows
export const INLINE_FLOW_TYPE = "INLINE_FLOW_TYPE";

type MemberModalFlowType = {
    type: typeof MODAL_FLOW_TYPE;
};

type MemberInlineFlowType = {
    type: typeof INLINE_FLOW_TYPE;
};

export type MemberPageFlowType = {
    type: typeof PAGE_FLOW_TYPE;
    loginPath: string;
    registerPath: string;
    successPath: string;
    successPathRedirectMethod?: "history" | "location"; // WICKET: Remove this when tillsammans has been migrated
};

export const MEMBER_MODAL_FLOW: MemberModalFlowType = {
    type: MODAL_FLOW_TYPE,
};

export const MEMBER_INLINE_FLOW: MemberInlineFlowType = {
    type: INLINE_FLOW_TYPE,
};

export const MEMBER_PAGE_FLOW: MemberPageFlowType = {
    type: PAGE_FLOW_TYPE,
    loginPath: "/loggain",
    registerPath: "/blimedlem",
    successPath: "/oversikt",
};

export type FlowType = MemberModalFlowType | MemberPageFlowType | MemberInlineFlowType;

// eslint-disable-next-line @nx/enforce-module-boundaries
import * as PurchaseActions from "@atg-horse-shared/purchase/src/domain/purchaseActions";
import {
    LOADING,
    LegacyOK as OK,
} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import * as HarryActions from "./harryActions";

type State = {
    available: boolean;
    status: {
        code: string;
        isLoading: boolean;
    };
};

const initialSubscriptionState = {
    available: false,
    status: {
        code: "ok",
        isLoading: false,
    },
};

export function harrySubscriptionAvailability(
    state: State = initialSubscriptionState,
    action: {
        [key: string]: any;
    },
) {
    switch (action.type) {
        case PurchaseActions.START_PURCHASE_FLOW:
            return initialSubscriptionState;
        case HarryActions.REQUEST_HARRY_SUBSCRIPTION_AVAILABLE:
            return {
                ...state,
                status: LOADING,
            };
        case HarryActions.RECEIVE_HARRY_SUBSCRIPTION_AVAILABLE:
            if (action.error) {
                return {
                    ...state,
                    status: action.payload.status,
                };
            }

            return {
                ...state,
                available: action.payload.harrySubscriptionAvailable,
                status: OK,
            };
        default:
            return state;
    }
}
